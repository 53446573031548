import { useEffect } from "react";
import socketio from "socket.io-client";
import { useLocation } from "react-router";
import { connect, useSelector } from "react-redux";

import { getBackendUrl } from "../../helpers/common";
import { initializeSocketConnection } from "../../store/actions/action";

import Footer from "../../components/Footer/footer";
import MetaFooter from "../../components/Footer/MetaFooter";
import Navbar from "../../components/navbar/navbar";
import Aux from "../Auxillary/auxillary";

const style = {
  marginTop: "0px",
  backgroundColor: "#120D17",
  minHeight: "100%",
};
const Layout = (props) => {
  let location = useLocation();

  useEffect(() => {
    const connection = getBackendUrl(process.env.REACT_APP_ENV);
    try {
      const socket = socketio.connect(connection, {
        transports: ["websocket"],
      });
      props.initializeSocketConnection({
        data: socket,
        isConnected: true,
      });
      return () => {
        socket.disconnect();
      };
    } catch (error) {
      props.initializeSocketConnection({
        data: error,
        isConnected: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Aux>
      <Navbar></Navbar>
      <main className="wrapper" style={style}>
        {props.children}
      </main>
      {location.pathname === "/home" && <Footer />}
      {location.pathname !== "/home" && <MetaFooter />}
    </Aux>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeSocketConnection: (data) =>
      dispatch(initializeSocketConnection(data)),
  };
};

export default connect(null, mapDispatchToProps)(Layout);
