import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import TokenDetailsTab from "./TokenDetailsTab";
import AllocationTab from "./AllocationTab";

import "./ProjectDetails.scss";

import web from "./assets/web.png";
import twitter from "./assets/twitter.png";
import telegram from "./assets/telegram.png";
import medium from "./assets/medium.png";
import github from "./assets/github.png";

const ProjectDetails = ({ singlePoolData }) => {
  const [currentTab, setCurrentTab] = useState("Project");

  const changeProjectTab = (tab) => {
    setCurrentTab(tab);
  };
  return (
    <div className="projectdetails">
      <div className="projectdetails-header">
        <div className="projectdetails-header-left">
          <div
            className={
              currentTab === "Project"
                ? "projectdetails-tab active"
                : "projectdetails-tab"
            }
            onClick={() => changeProjectTab("Project")}
          >
            Project Details
          </div>
          <div
            className={
              currentTab === "Token"
                ? "projectdetails-tab active"
                : "projectdetails-tab"
            }
            onClick={() => changeProjectTab("Token")}
          >
            Token Details
          </div>
          {singlePoolData.projectStatus === "ended" && (
            <div
              className={
                currentTab === "Allocation"
                  ? "projectdetails-tab active"
                  : "projectdetails-tab"
              }
              onClick={() => changeProjectTab("Allocation")}
            >
              Allocation
            </div>
          )}
        </div>
        {/* {singlePoolData.projectStatus === "ended" && (
          <Link to="/" className="projectdetails-header-right">
            Winner List
          </Link>
        )} */}
      </div>
      {currentTab === "Project" && (
        <div className="projectdetails-content">
          <div className="projectdetails-about">
            <h1>About Project</h1>
            <div className="social-icons">
              {singlePoolData.projectLinks.website !== "" && (
                <a
                  target="_blank"
                  href={singlePoolData.projectLinks.website}
                  rel="noreferrer"
                >
                  <img src={web} alt="web" />
                </a>
              )}
              {singlePoolData.projectLinks.twitter !== "" && (
                <a
                  target="_blank"
                  href={singlePoolData.projectLinks.twitter}
                  rel="noreferrer"
                >
                  <img src={twitter} alt="twitter" />
                </a>
              )}
              {singlePoolData.projectLinks.telegram !== "" && (
                <a
                  target="_blank"
                  href={singlePoolData.projectLinks.telegram}
                  rel="noreferrer"
                >
                  <img src={telegram} alt="telegram" />
                </a>
              )}
              {singlePoolData.projectLinks.medium !== "" && (
                <a
                  target="_blank"
                  href={singlePoolData.projectLinks.medium}
                  rel="noreferrer"
                >
                  <img src={medium} alt="medium" />
                </a>
              )}
              {singlePoolData.projectLinks.github !== "" && (
                <a
                  target="_blank"
                  href={singlePoolData.projectLinks.github}
                  rel="noreferrer"
                >
                  <img src={github} alt="github" />
                </a>
              )}
            </div>
          </div>
          <p className="projectdetails-details">
            {singlePoolData.projectDescription}
          </p>

          <div className="ido-details">
            <div className="ido-details-col">
              <p className="heading">Token Sale Date</p>
              {singlePoolData.isTBA === true ? (
                <p className="value">TBA</p>
              ) : (
                <p className="value">
                  {moment
                    .utc(singlePoolData.tokenDetails.tokenSaleDate)
                    .format("Do MMM YYYY HH:mm A")}{" "}
                  UTC
                </p>
              )}
            </div>
            <div className="ido-details-col">
              <p className="heading">Token Distribution Date</p>
              {new Date(singlePoolData.tokenDetails.tokenDistributionDate) <
              new Date(singlePoolData.idoDates.roundTwoEndDate) ? (
                <p className="value">TBA</p>
              ) : (
                <p className="value">
                  {moment
                    .utc(singlePoolData.tokenDetails.tokenDistributionDate)
                    .format("Do MMM YYYY HH:mm A")}{" "}
                  UTC
                </p>
              )}
            </div>
            <div className="ido-details-col">
              <p className="heading">Total Fundraise</p>
              {singlePoolData.tokenDetails.totalFundraise == 0 ? (
                <p className="value">TBA</p>
              ) : (
                <p className="value">
                  ${singlePoolData.tokenDetails.totalFundraise}
                </p>
              )}
            </div>
            <div className="ido-details-col">
              <p className="heading">Inital Market Cap</p>
              {singlePoolData.tokenDetails.initialMarketCap == 0 ? (
                <p className="value">TBA</p>
              ) : (
                <p className="value">
                  ${singlePoolData.tokenDetails.initialMarketCap}
                </p>
              )}
            </div>
          </div>

          <p className="restricted">
            Restricted Countries: {singlePoolData.projectRestriction}
          </p>

          <div className="ido-links">
            {singlePoolData.projectLinks.whitepaper !== "" && (
              <a
                target="_blank"
                href={singlePoolData.projectLinks.whitepaper}
                rel="noreferrer"
              >
                Whitepaper Link
              </a>
            )}

            {singlePoolData.projectLinks.onepager !== "" && (
              <a
                target="_blank"
                href={singlePoolData.projectLinks.onepager}
                rel="noreferrer"
              >
                One Paper
              </a>
            )}
            {/* {singlePoolData.projectLinks.whitepaper !== "" && (
              <a
                target="_blank"
                href={singlePoolData.projectLinks.whitepaper}
                rel="noreferrer"
              >
                Tokenomics
              </a>
            )} */}
            {singlePoolData.tokenDetails.auditReportLink !== "" && (
              <a
                target="_blank"
                href={singlePoolData.tokenDetails.auditReportLink}
                rel="noreferrer"
              >
                Audit Report
              </a>
            )}
          </div>
        </div>
      )}

      {currentTab === "Token" && (
        <TokenDetailsTab singlePoolData={singlePoolData} />
      )}
      {currentTab === "Allocation" && (
        <AllocationTab singlePoolData={singlePoolData} />
      )}
    </div>
  );
};

export default ProjectDetails;
