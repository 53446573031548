/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { checkConnectionNetwork } from "../../../../Web3/web3";
import api from "../../../../config/axios-config";

import { connect } from "react-redux";

import { toggleModal } from "../../../../store/actions/action";

import "./JoinWishlist.css";
import CountryList from "./Countries.json";
import { toast } from "react-toastify";
import apiCall from "../../../../config/apiCall";

const JoinWishlist = (props) => {
  const [formData, setFormData] = useState({
    countryOfResidence: "Afghanistan",
    walletAddress: "",
  });

  const submitJoinWishlist = async (e) => {
    e.preventDefault();

    if (formData.walletAddress === "") {
      toast.info("Please connect Wallet");
      getWalletAddress();
      return;
    }

    if (formData.firstName === "") {
      toast.info("Enter First Name");
      return;
    }

    if (formData.lastName === "") {
      toast.info("Enter Last Name");
      return;
    }

    /* if (formData.dob === "") {
      toast.info("Enter DOB");
      return;
    }

    if (formData.stakedAmount === "" || formData.stakedAmount === 0) {
      toast.info("Enter Staked Amount");
      return;
    } */

    if (formData.twitter === "" || formData.twitter === undefined) {
      toast.info("Enter Twitter Link");
      return;
    }
    /* 
    if (formData.telegram === "" || formData.telegram === undefined) {
      toast.info("Enter Telegram Link");
      return;
    }

    if (formData.medium === "" || formData.medium === undefined) {
      toast.info("Enter Medium Link or NA");
      return;
    }

    if (formData.youtube === "" || formData.youtube === undefined) {
      toast.info("Enter Youtube Link or NA");
      return;
    }

    if (formData.others === "" || formData.others === undefined) {
      toast.info("Enter Others Link or NA");
      return;
    } */

    let userData = {
      projectId: location.pathname.split("/")[2],
      firstName: formData.firstName,
      secondName: formData.secondName,
      lastName: formData.lastName,
      // dob: new Date(formData.dob),
      emailId: formData.emailId,
      countryOfResidence: formData.countryOfResidence,
      // identityProof: formData.identityProof,
      walletAddress: formData.walletAddress,
      // stakedAmount: formData.stakedAmount,

      socialMedia: {
        twitter: formData.twitter,
        // medium: formData.medium,
        // telegram: formData.telegram,
        // youtube: formData.youtube,
        // others: formData.others,
      },
    };

    // console.log(userData);

    let checkUniqueWalletAddress = await apiCall.post(
      "users/checkIfUserWhitelisted",
      {
        projectId: location.pathname.split("/")[2],
        walletAddress: formData.walletAddress,
      }
    );

    checkUniqueWalletAddress = checkUniqueWalletAddress.data.data;

    // console.log(checkUniqueWalletAddress);

    if (checkUniqueWalletAddress === true) {
      toast.info("This address has already been whitelisted");
      return;
    }

    // console.log(checkUniqueWalletAddress);

    // console.log(userData);

    api
      .post("users/registration", { data: userData })
      .then(() => {
        toast.success("Details Successfully Submitted");
      })
      .catch((error) => {
        console.log(error);
        toast.info("There was error in submitting the form");
      });

    props.toggle_Modal({
      show: false,
    });
  };

  const handleInputChange = (e) => {
    let data = formData;
    data = {
      ...data,
      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };

  const getWalletAddress = async () => {
    let { account } = await checkConnectionNetwork();
    let { address } = account;

    let data = formData;
    data = {
      ...data,
      walletAddress: address,
    };
    setFormData(data);
  };

  useEffect(() => {
    getWalletAddress();
  }, []);

  return (
    <div className="joinwishlist">
      <h1 className="heading">Join Whitelist</h1>

      <form onSubmit={submitJoinWishlist} className="joinwishlist-content">
        <div className="split-input">
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="firstName"
              aria-describedby="fullNameHelp"
              placeholder="Enter your First name"
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="secondName">Middle Name</label>
            <input
              type="text"
              className="form-control"
              id="secondName"
              name="secondName"
              aria-describedby="midddleNameHelp"
              placeholder="Enter your Midlle name"
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              name="lastName"
              aria-describedby="fullNameHelp"
              placeholder="Enter your Last name"
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            className="form-control"
            id="emailId"
            name="emailId"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            onChange={handleInputChange}
            required
          />
        </div>
        {/* <div className="split-input">
          <div className="form-group">
            <label htmlFor="dob">Date Of Birth</label>
            <input
              type="date"
              className="form-control"
              id="dob"
              name="dob"
              aria-describedby="emailHelp"
              placeholder="Enter your dob"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="identityProof">Identity Proof (Link)</label>
            <input
              type="text"
              className="form-control"
              id="identityProof"
              name="identityProof"
              aria-describedby="identityProofHelp"
              placeholder="Enter link of ID proof"
              onChange={handleInputChange}
              required
            />
          </div>
        </div> */}
        <div className="split-input">
          <div className="form-group">
            <label htmlFor="countryOfResidence">County of Residance</label>
            <select
              className="form-select"
              id="countryOfResidence"
              placeholder="Select Country"
              value={formData.countryOfResidence}
              name="countryOfResidence"
              onChange={handleInputChange}
            >
              {CountryList.map((country, index) => (
                <option key={index} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="form-group">
            <label htmlFor="stakedAmount">Staked Amount</label>
            <input
              type="text"
              className="form-control"
              id="stakedAmount"
              name="stakedAmount"
              aria-describedby="stakedAmountlHelp"
              placeholder="Enter Staked Amount"
              onChange={handleInputChange}
            />
          </div> */}
        </div>
        <div className="split-input">
          {/* <div className="form-group">
            <label htmlFor="exampleInputEmail1">Qualified for Tier?</label>
            <select
              className="form-select"
              id="identityproof"
              aria-describedby="identityproof"
              placeholder="Select Identity Proof"
              name="identityproof"
            >
              <option value="yes">yes</option>
              <option value="no">no</option>
            </select>
          </div> */}
          <div className="form-group">
            <label htmlFor="walletAddress">Wallet Address</label>
            <input
              type="text"
              className="form-control"
              id="walletAddress"
              name="walletAddress"
              aria-describedby="walletAddressHelp"
              placeholder="Enter Wallet Address"
              // onChange={handleInputChange}
              readOnly
              disabled
              value={formData.walletAddress}
            />
          </div>
        </div>
        {/* <div className="form-group participation">
          <p className="participation-text">
            Previous Participation in Projects
          </p>
          <button
            type="button"
            className={`${
              formData.previousParticipation === 1
                ? "participation-btn active"
                : "participation-btn"
            }`}
            onClick={() => handleDropdownChange("previousParticipation", 1)}
          >
            Yes
          </button>
          <button
            type="button"
            className={`${
              formData.previousParticipation === 0
                ? "participation-btn active"
                : "participation-btn"
            }`}
            onClick={() => handleDropdownChange("previousParticipation", 0)}
          >
            No
          </button>
        </div> */}

        <div className="split-input">
          <div className="form-group">
            <label htmlFor="twitter">Twitter Link</label>
            <input
              type="text"
              className="form-control"
              id="twitter"
              name="twitter"
              aria-describedby="twitterHelp"
              placeholder="Enter Twitter Link"
              onChange={handleInputChange}
            />
          </div>
          {/* <div className="form-group">
            <label htmlFor="medium">Medium Link</label>
            <input
              type="text"
              className="form-control"
              id="medium"
              name="medium"
              aria-describedby="mediumHelp"
              placeholder="Enter Medium Link"
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="telegram">Telegram Link</label>
            <input
              type="text"
              className="form-control"
              id="telegram"
              name="telegram"
              aria-describedby="telegramHelp"
              placeholder="Enter Telegram Link"
              onChange={handleInputChange}
            />
          </div> */}
        </div>

        {/* <div className="split-input">
          <div className="form-group">
            <label htmlFor="youtube">Youtube Link</label>
            <input
              type="text"
              className="form-control"
              id="youtube"
              name="youtube"
              aria-describedby="emailHelp"
              placeholder="Enter Youtube Link"
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="others">Others Link</label>
            <input
              type="text"
              className="form-control"
              id="others"
              name="others"
              aria-describedby="othersHelp"
              placeholder="Enter Others Link"
              onChange={handleInputChange}
            />
          </div>
        </div> */}

        <button type="submit" className="joinwishlist-submit">
          Submit
        </button>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_Modal: (show) => dispatch(toggleModal(show)),
  };
};

export default connect(null, mapDispatchToProps)(JoinWishlist);
