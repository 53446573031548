import { Form, Col } from "react-bootstrap";
import React, { useReducer, useState } from "react";
import api from "../../../config/axios-config";
import Select from "react-select";
import { Buttons } from "../UI/Buttons";
import "./Form.css";
import CountryList from "./Countries.json";

import { checkValidContractAddress } from "../../../Web3/web3";
import { connect } from "react-redux";
import { toggleModal } from "../../../store/actions/action";
import { toast } from "react-toastify";

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};

const ApplyIdoForm = (props) => {
  const [formData, setFormData] = useReducer(formReducer, {});
  const [projectCategory, setProjectCategory] = useState([]);
  const [tokenUtility, setTokenUtility] = useState([]);

  const handleChange = (e) => {
    setFormData({
      name: e.target.name,
      value: e.target.value,
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();

    let projectCategoryArray = projectCategory.map(function (elem) {
      return elem.value;
    });

    let tokenUtilityArray = tokenUtility.map(function (elem) {
      return elem.value;
    });

    let checkContractAddress = await checkValidContractAddress(
      formData["tokenSmartContract"]
    );

    if (formData["projectDescription"]?.length > 600) {
      toast.info("Project Description cannot be more than 500 words");
      return;
    }
    if (formData["tokenTicker"]?.length > 50) {
      toast.info("Token Ticker cannot be more than 5-6 words");
      return;
    }
    if (projectCategoryArray.length === 0) {
      toast.info("Please select a project category");
      return;
    }
    if (tokenUtilityArray.length === 0) {
      toast.info("Please select a token utility");
      return;
    }
    if (!checkContractAddress) {
      toast.info("Please check the contract address");
      return;
    }

    if (
      formData["alreadyFundRaised"] === "" ||
      formData["alreadyFundRaised"] === undefined
    ) {
      formData["alreadyFundRaised"] = "ÿes";
    }
    if (
      formData["projectBlockchain"] === "" ||
      formData["projectBlockchain"] === undefined
    ) {
      formData["projectBlockchain"] = "ETH";
    }
    formData["entityRegisteredInCountry"] = CountryList[0].name;
    formData["projectCategory"] = projectCategoryArray;
    formData["tokenUtility"] = tokenUtilityArray;
    formData["howMuchFundRaisePlanning"] = parseFloat(formData["howMuchFundRaisePlanning"]);
    formData["totalTGEValuation"] = parseFloat(formData["totalTGEValuation"]);
    formData["noOfTokensRelasedTGE"] = parseFloat(formData["noOfTokensRelasedTGE"]);
    // formData["howMuchFundRaisePlanning"] = parseFloat(formData["howMuchFundRaisePlanning"]);
    // formData["howMuchFundRaisePlanning"] = parseFloat(formData["howMuchFundRaisePlanning"]);
    
    console.log(formData);
    api
      .post("projects/registration", { data: formData })
      .then((data) => {
        console.log(data);
        toast.success("Project Successfully registered");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something Went Wrong");
      });
    console.log("data submitted");
    // props.toggle_Modal({ show: false });

    // api
    //   .post("projects/registration", { data: formData })
    //   .then((data) => {
    //     console.log(data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // console.log(formData);
  };
  return (
    <Form onSubmit={submitForm}>
      <Form.Row>
        <Form.Group as={Col} controlId="projectName">
          <Form.Label>Project Name</Form.Label>
          <Form.Control
            name="projectName"
            type="text"
            placeholder="Project Name"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="projectDescription">
          <Form.Label>Project Description</Form.Label>
          <Form.Control
            name="projectDescription"
            type="text"
            placeholder="Project Detailed Description"
            onChange={handleChange}
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="team">
          <Form.Label>Team (with bio and social media)</Form.Label>
          <Form.Control
            name="team"
            type="text"
            placeholder="Team ( with bio and social media)"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="entityRegisteredInCountry">
          <Form.Label>Entity registered in which country</Form.Label>
          {/* <Form.Control
            name="entityRegisteredInCountry"
            type="text"
            placeholder="Entity registered in which country"
            onChange={handleChange}
          /> */}

          <select
            id="entityRegisteredInCountry"
            name="entityRegisteredInCountry"
            onChange={handleChange}
            className="form-control"
            placeholder="Entity registered in which country"
            required
          >
            <option disabled>Please Select</option>
            {CountryList.map((country) => (
              <option key={country.name} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="socialMediaHandleOfProjects">
          <Form.Label>Social media handle of projects</Form.Label>
          <Form.Control
            name="socialMediaHandleOfProjects"
            type="text"
            placeholder="Social media handle of projects"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="projectFounderStatus">
          <Form.Label>Project Founder Status</Form.Label>
          <Form.Control
            name="projectFounderStatus"
            type="text"
            placeholder="Project founder status (known/anonymous)"
            onChange={handleChange}
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="alreadyFundRaised">
          <Form.Label>Have you already raised funds? </Form.Label>
          {/* <Form.Control
            name="alreadyFundRaised"
            type="text"
            placeholder="Have you already raised funds? If yes then how much?"
            onChange={handleChange}
          /> */}
          <select
            id="alreadyFundRaised"
            name="alreadyFundRaised"
            onChange={handleChange}
            className="form-control"
            placeholder="Have you already raised funds? If yes then how much?"
            required
          >
            <option disabled>Please Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </Form.Group>
        <Form.Group as={Col} controlId="howMuchFundRaisePlanning">
          <Form.Label>How much plan to raise in the IDO?</Form.Label>
          <Form.Control
            name="howMuchFundRaisePlanning"
            type="number"
            placeholder="How much are you planning on raising on the IDO?"
            onChange={handleChange}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="totalTGEValuation">
          <Form.Label>Total valuation at TGE</Form.Label>
          <Form.Control
            name="totalTGEValuation"
            type="number"
            placeholder="(Initial market cap and FDM)"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="tokenUtility">
          <Form.Label>Token utility</Form.Label>
          {/* <Form.Control
            name="tokenUtility"
            type="text"
            placeholder="Token utility"
            onChange={handleChange}
          /> */}
          <Select
            isMulti
            name="tokenUtility"
            options={[
              {
                value: "Platform Token",
                label: "Platform Token",
              },
              {
                value: "Governance token",
                label: "Governance token",
              },
              {
                value: "Utility token",
                label: "Utility token",
              },
              {
                value: "Others",
                label: "Others",
              },
            ]}
            onChange={(e) => setTokenUtility(e)}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="tokenVestingSchedule">
          <Form.Label>Token vesting schedule</Form.Label>
          <Form.Control
            name="tokenVestingSchedule"
            type="text"
            placeholder="Token vesting schedule"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="noOfTokensRelasedTGE">
          <Form.Label>No of Token released at TGE</Form.Label>
          <Form.Control
            name="noOfTokensRelasedTGE"
            type="text"
            placeholder="No of Token released at TGE"
            onChange={handleChange}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="tokenPricePartnerName">
          <Form.Label>Token price and listing expected price</Form.Label>
          <Form.Control
            name="tokenPricePartnerName"
            type="text"
            placeholder="Token price and listing expected price"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="listingSchedule">
          <Form.Label>Listing schedule</Form.Label>
          <Form.Control
            name="listingSchedule"
            type="text"
            placeholder="Listing schedule"
            onChange={handleChange}
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="productViability">
          <Form.Label>Product viability</Form.Label>
          <Form.Control
            name="productViability"
            type="text"
            placeholder="Product viability"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="documents">
          <Form.Label>Documents</Form.Label>
          <Form.Control
            name="documents"
            type="text"
            placeholder="Pitch deck , one paper , lightpaper and whitepaper"
            onChange={handleChange}
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="tokenomicsLink">
          <Form.Label>Tokenomics Link</Form.Label>
          <Form.Control
            name="tokenomicsLink"
            type="text"
            placeholder="Tokenomics Link"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="investorsPartnerName">
          <Form.Label>Investors and partners name</Form.Label>
          <Form.Control
            name="investorsPartnerName"
            type="text"
            placeholder="Investors and partners name"
            onChange={handleChange}
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="tokenTicker">
          <Form.Label>Token ticker</Form.Label>
          <Form.Control
            name="tokenTicker"
            type="text"
            placeholder="Token ticker"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="tokenSmartContract">
          <Form.Label>Token smart contract address</Form.Label>
          <Form.Control
            name="tokenSmartContract"
            type="text"
            placeholder="Token smart contract address"
            onChange={handleChange}
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="projectCategory">
          <Form.Label>Project Category</Form.Label>
          {/* <Form.Control
            name="projectCategory"
            type="text"
            placeholder="Project Category (ex DeFi, NFTs)"
            onChange={handleChange}
          /> */}
          {/* <select
            id="projectBlockchain"
            name="projectBlockchain"
            onChange={handleChange}
            className="form-control"
            placeholder="Project blockchain (ex  BSC, Polkadot, polygon)"
          >
            <option disabled>Please Select</option>
            <option value="DeFi">DeFi</option>
            <option value="NFT">NFT</option>
            <option value="Game">Game</option>
            <option value="layer 1">layer 1</option>
            <option value="layer 2">layer 2</option>
            <option value="others">others</option>
          </select> */}
          <Select
            isMulti
            name="colors"
            options={[
              {
                value: "Defi",
                label: "Defi",
              },
              {
                value: "NFT",
                label: "NFT",
              },
              {
                value: "Game",
                label: "Game",
              },
              {
                value: "Layer 1",
                label: "Layer 1",
              },
            ]}
            onChange={(e) => setProjectCategory(e)}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="projectBlockchain">
          <Form.Label>Project Blockchain</Form.Label>
          {/* <Form.Control
            name="projectBlockchain"
            type="text"
            placeholder="Project blockchain (ex  BSC, Polkadot, polygon)"
            onChange={handleChange}
          /> */}

          <select
            id="projectBlockchain"
            name="projectBlockchain"
            onChange={handleChange}
            className="form-control"
            placeholder="Project blockchain (ex  BSC, Polkadot, polygon)"
            required
          >
            <option disabled>Please Select</option>
            <option value="ETH">ETH</option>
            <option value="BSC">BSC</option>
            <option value="POLYGON">POLYGON</option>
            <option value="OTHERS">OTHERS</option>
          </select>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="projectTags">
          <Form.Label>Projects tags</Form.Label>
          <Form.Control
            name="projectTags"
            type="text"
            placeholder="Projects tags"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="otherInfo">
          <Form.Label>Other info</Form.Label>
          <Form.Control
            name="otherInfo"
            type="text"
            placeholder="Other info"
            onChange={handleChange}
            required
          />
        </Form.Group>
      </Form.Row>
      <Buttons
        btnName={"Submit"}
        className={"submit-btn"}
        modal={{ isModal: false }}
        type={"submit"}
      ></Buttons>
    </Form>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_Modal: (show) => dispatch(toggleModal(show)),
  };
};

export default connect(null, mapDispatchToProps)(ApplyIdoForm);
// export default ApplyIdoForm;
