import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../../../../components/Loader/Loader'
import { web3Class } from '../../../../../Web3/web3Class'

function PoolSaleStatus({tier, singlePoolData, openJoinPool, isJoinPoolDisabled, buttonDisabled}) {
    // console.log("SPD", singlePoolData);
    const [loading, setLoading] = useState(false)
    // const [availableToken, setAvailableToken] = useState()
    const [tierPercentage, setTierPercentage] = useState(0);
    const [tokensLeft, setTokensLeft] = useState()
    const [dollarValueLeft, setDollarValueLeft] = useState()
    
    useEffect(() => {
        const fetchData = async () => {
            let web3Instance = new web3Class(singlePoolData);

            let tierMax = await web3Instance.calculateTotalAvailableTokensInAllTiers();
            setTokensLeft(tierMax)
            // console.log(tierMax, "Tmm");
            let tokenPrice = singlePoolData.tokenDetails.tokenPrice
            setDollarValueLeft(tierMax * tokenPrice)
        };

        fetchData();
    }, []);    

    const isSocketConnected = useSelector(
        (state) => state.reducer.socket.isConnected
    );
    const socket = useSelector((state) => state.reducer.socket.data);

    useEffect(() => {
        if (isSocketConnected) {
            // console.log("connected");
            socket.on("project-progress", (projectData) => {
                console.log("project Data Socket", projectData);
                if (projectData.projectId === singlePoolData._id){
                    setTierPercentage(projectData.overallPercentage);
                    setTokensLeft(projectData.availableTokens)
                    let tokenPrice = singlePoolData.tokenDetails.tokenPrice
                    setDollarValueLeft(projectData.availableTokens * tokenPrice)
                    // console.log("RDV", projectData.availableTokens * tokenPrice, projectData.availableTokens);
                }
                // console.log("percent%%%", projectData.overallPercentage);
        });
        }
    }, [isSocketConnected, singlePoolData, socket]);

    if(loading){
        return <Loader width="50px" />
    }

    const isDisabled = (tier) => {
    //   console.log("tttt", tier);
        switch (tier) {
            case 1:
                return isJoinPoolDisabled.tier1
            case 2:
                return isJoinPoolDisabled.tier2
            case 3:
                return isJoinPoolDisabled.tier3
            case 4:
                return isJoinPoolDisabled.tier4
        
            default:
                return true
        }
    }
    
  return (
    <>
       {
        //    tierPercentage === 100 ? 
           Math.floor(tokensLeft) == 0 || Math.floor(dollarValueLeft) == 0 ? 
           "SOLD OUT" 
           : 
           <button
                className="active"
                onClick={() => openJoinPool(tier)}
                disabled={buttonDisabled}
            >
                Join Pool
            </button>  
       }
    </>
  )
}

export default PoolSaleStatus