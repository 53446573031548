import { Dropdown } from "react-bootstrap";
export const Buttons = (props) => {
  return (
    <button
      type={props.type ? props.type : "button"}
      className={props.className ? props.className + " commonBtn" : "commonBtn"}
      disabled={props.disabled}
      onClick={() =>
        props.modal.isModal
          ? props.toggle_Modal({
              show: props.modal.value,
              modalType: props.modal.modalType,
              modalName: props.modal.modalName,
              projectId: props.modal.projectId,
            })
          : null
      }
    >
      {props.btnName}
    </button>
  );
};

export const NormalButton = (props) => {
  return (
    <button
      type={props.type ? props.type : "button"}
      className={props.className ? props.className + " commonBtn" : "commonBtn"}
      onClick={() =>
        props.onClick(props.projectId, props.projectContractAddress)
      }
    >
      {props.btnName}
    </button>
  );
};

export const CustomDropdown = (props) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        type={props.type ? props.type : "button"}
        className={
          props.connection.isConnected
            ? props.className + " isConnected commonBtn"
            : props.className + " commonBtn"
        }
      >
        {props.connection.isConnected ? null : (
          <img
            src={props.wallet}
            style={{ width: "25px", marginRight: "8px" }}
          />
        )}
        {props.connection.isConnected
          ? props.account.address.substring(
              props.account.address.length - 4,
              props.account.address.length
            ) +
            "..." +
            props.account.address.substring(0, 5)
          : props.btnName}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => props.connectMetamask()}>
          <img src={props.bsc}></img>BSC Wallet
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
