import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAccountChange } from "../../../../hooks";
import {
  setProjectContract,
  toggleModal,
} from "../../../../store/actions/action";
import { calculatePriceOfTokens } from "../../../../utils/helpers";
import { web3Class } from "../../../../Web3/web3Class";
import "./ProjectDetails.scss";

const AllocationTab = (props) => {
  const { singlePoolData } = props;
  let userAccount = useAccountChange();
  const [claimDisable, setClaimDisable] = useState(true);
  const [tokenWithdrawn, setTokenWithdrawn] = useState(false);
  const [userAddress, setUserAddress] = useState("loading...");
  const [withdrawableAmount, setWithdrawableAmount] = useState("loading...");
  const [purchasedTokens, setPurchasedTokens] = useState("loading...");

  const getData = async () => {
    // const contractDetails = {
    //   contractAddress: singlePoolData.projectContractAddress,
    //   networkId: singlePoolData.projectNetwork.networkId,
    //   currencyAbi: singlePoolData.abi.currencyABI,
    //   tokenAbi: singlePoolData.abi.tokenABI,
    //   currencyAddress: singlePoolData.smartContractAddress.currency,
    //   tokenAddress: singlePoolData.smartContractAddress.token,
    // };
    let web3Instance = new web3Class(singlePoolData);

    try {
      let saleData = await web3Instance.checkSales();
      console.log(saleData);

      // saleData.amount = saleData.amount
      setPurchasedTokens(parseFloat(saleData.amount).toFixed(2));

      saleData.amount = calculatePriceOfTokens(
        saleData.amount,
        singlePoolData.tokenDetails.tokenPrice
      );

      setWithdrawableAmount(saleData.amount);

      if (saleData.tokensWithdrawn === false && saleData.amount > 0) {
        setTokenWithdrawn(false);
        setClaimDisable(false);
      }

      if (saleData.tokensWithdrawn === true) {
        setTokenWithdrawn(true);
        setClaimDisable(true);
      }

      if (saleData.investor === "0x0000000000000000000000000000000000000000") {
        setClaimDisable(true);
      }

      //   if (saleData.amount === 0) {
      //     setTokenWithdrawn(true);
      //     setClaimDisable(true);
      //   }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userAccount !== undefined) {
      let accountShort =
        userAccount.substring(0, 4) +
        "....." +
        userAccount.substring(userAccount.length - 4, userAccount.length);
      getData();
      setUserAddress(accountShort);
    }
  }, [userAccount]);

  const openClaimModal = async () => {
    if (userAccount.length < 0) {
      return;
    }

    props.toggle_Modal({
      show: true,
      modalType: "claimFromPool",
      modalName: "Claim From Pool",
    });
  };

  if (userAccount === undefined) {
    <div className="allocation">
      <div className="allocation__wrapper">
        <p className="connectText">Please Connect to MetaMask</p>
      </div>
    </div>;
  }

  return (
    <div className="allocation">
      <div className="allocation__wrapper">
        <div className="allocation__card">
          <div className="allocation__card-grid">
            <div className="heading">Address</div>
            <div>{userAddress}</div>
            <div className="heading">Invested Amount</div>
            <div>${withdrawableAmount}</div>
            <div className="heading">Purchased Tokens</div>
            <div>
              {tokenWithdrawn ? "0" : purchasedTokens}{" "}
              {singlePoolData.tokenDetails.tokenSymbol}
            </div>
            <div className="heading">Vesting period</div>
            <div>{singlePoolData.tokenDetails.vesting}</div>
            <div className="heading">Distribution Mode</div>
            <div>Airdrop</div>
          </div>

          {/* <button
                className="claimBtn"
                disabled={claimDisable}
                onClick={openClaimModal}
              >
                {tokenWithdrawn === false ? "Claim your Token" : "Claimed"}
              </button> */}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_Modal: (show) => dispatch(toggleModal(show)),
    setProjectContractDetails: (data) => dispatch(setProjectContract(data)),
  };
};

export default connect(null, mapDispatchToProps)(AllocationTab);
