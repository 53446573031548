export const CONNECT_METAMASK = "connect_metamask";
export const DISCONNECT_METAMASK = "disconnect_metamask";

export const ACCOUNT_DETAILS = "account_details";
export const TRANSACTION_DETAILS = "transaction_details";
export const ADDRESS_WHITELISTING = "address_whitelisting";

export const TOGGLE_MODAL = "toggle_modal";
export const PROJECT_TABS = "project_tabs";

export const NOTIFICATIONS = "notifications";

export const SET_PROJECT_CONTRACT = "set_project_contract";
export const SET_SINGLE_POOL_DETAILS = "set_single_pool_details";

export const INITIALIZE_SOCKET = "initialize_socket";
