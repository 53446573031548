import React from "react";
import "./ConnectWallet.css";
import { connect } from "react-redux";
import polygon from "./assets/polygon.png";
import bsc from "./assets/bsc.png";
import wallet from "./assets/wallet.png";
import tick from "./assets/tick.png";
// import polygon from "./assets/tick.png";
import avax from "./assets/avax.png";
import { toggleModal } from "../../../../store/actions/action";
import metamask from "./assets/metamask.png";
import { useNetworkChange } from "../../../../hooks";
import { switchNetwork } from "../../../../Web3/web3";

const ConnectWallet = ({ toggle_Modal }) => {
  let network = useNetworkChange();

  const changeNetwork = async (id) => {
    try {
      await switchNetwork(id);
      toggle_Modal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="connectWallet">
      <h1 className="heading">Select Your Network</h1>
      <h5 className="subheading">
        New to ASVA ?{" "}
        <a
          className="learn"
          target="_blank"
          href="https://asvalabs.gitbook.io/asva-labs/"
        >
          Learn more
        </a>
      </h5>

      <div className="networkList">
        <button
          className="networkList__item bsc"
          onClick={() => changeNetwork(56)}
        >
          <img className="icon" src={bsc} alt="bsc" />
          <p className="name">BSC</p>

          {network === 56 && <img src={tick} alt="tick" className="tick" />}
        </button>
        <button
          className="networkList__item polygon"
          onClick={() => changeNetwork(137)}
        >
          <img className="icon" src={polygon} alt="bsc" />
          <p className="name">Polygon</p>

          {network === 137 && <img src={tick} alt="tick" className="tick" />}
        </button>
        <button
          className="networkList__item avax"
          onClick={() => changeNetwork(43114)}
        >
          <img className="icon" src={avax} alt="bsc" />
          <p className="name">Avalanche</p>

          {network === 43114 && <img src={tick} alt="tick" className="tick" />}
        </button>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    // connectMetamask: () => dispatch(isMetaMaskInstalled()),
    toggle_Modal: (show) => dispatch(toggleModal(show)),
  };
};

export default connect(null, mapDispatchToProps)(ConnectWallet);
