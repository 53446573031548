import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  buyPoolToken,
  fetchAccountDetails,
  getAddressBelongsToTier,
  getCurrencyBalance,
} from "../../../../Web3/web3";
import { toggleModal } from "../../../../store/actions/action";
import Loader from "../../../Loader/Loader";
import { useAccountChange } from "../../../../hooks";

import "./JoinPool.scss";
import avaxImg from "./assets/avax.png";
import maticImg from "./assets/matic.svg";
import bnbImg from "./assets/bnb.png";
import fraxImg from "./assets/frax.png";
import usdcImg from "./assets/usdc.png";
import usdtImg from "./assets/usdt.png";
import USDSVG from "./assets/usd.svg";
import {
  calculatePriceOfTokens,
  calculatePriceOfTokensRoundDown,
  convertNegetiveNumberToNumber,
  roundOffBuyValue,
} from "../../../../utils/helpers";
import { web3Class } from "../../../../Web3/web3Class";
import NetworkImg from "../../../NetworkImg/NetworkImg";

const JoinPool = (props) => {
  let account = useAccountChange();
  const currentBuyingTier = useSelector(
    (state) => state.reducer.projectContract.tier
  );
  const isSocketConnected = useSelector(
    (state) => state.reducer.socket.isConnected
  );
  const socket = useSelector((state) => state.reducer.socket.data);
  const [loading, setLoading] = useState(false);
  const { projectContract, singlePoolData } = props;
  const [maxAmount, setMaxAmount] = useState("loading...");
  const [userMaxAmountBuy, setUserMaxAmountBuy] = useState("loading...");
  const [allocationLeft, setAllocationLeft] = useState("loading...");
  const [amount, setAmount] = useState("");
  const [tokensAvailableInTier, setTokensAvailableInTier] = useState("loading");
  const [userCurrencyBalance, setUserCurrencyBalance] = useState("loading");
  const [userRecieveValue, setUserRecieveValue] = useState(0);

  const [joinPoolLoading, setJoinPoolLoading] = useState(false);

  const fetchUserCurrencyBalance = async () => {
    setLoading(true);
    let balance = await getCurrencyBalance();
    balance = Math.floor(balance);
    setUserCurrencyBalance(balance);
    setLoading(false);
  };

  useEffect(() => {
    if (account !== undefined) fetchUserCurrencyBalance();
  }, [account]);

  useEffect(() => {
    const fetchData = async () => {
      let web3Instance = new web3Class(singlePoolData);
      let tierMax = await web3Instance.getTierMaxAmountThatCanBeInvested(
        projectContract.tier || "0"
      );

      let userPurchased = await web3Instance.checkSales(
        projectContract.tier || "0"
      );

      let tokensAvailable = await web3Instance.getTokensAvailableInTier(
        projectContract.tier || "0"
      );

      // tierMax = roundOffBuyValue(tierMax);

      let actualAllocationleft = tierMax - userPurchased.amount;

      // actualAllocationleft =
      //   actualAllocationleft * singlePoolData.tokenDetails.tokenPrice;

      actualAllocationleft = calculatePriceOfTokens(
        actualAllocationleft,
        singlePoolData.tokenDetails.tokenPrice
      );

      // tokensAvailable =
      //   tokensAvailable * parseFloat(singlePoolData.tokenDetails.tokenPrice);

      tokensAvailable = calculatePriceOfTokens(
        tokensAvailable,
        singlePoolData.tokenDetails.tokenPrice
      );

      actualAllocationleft =
        convertNegetiveNumberToNumber(actualAllocationleft);

      // console.log(data, data2);
      setUserMaxAmountBuy(actualAllocationleft);

      // console.log(tierMax);

      tierMax = calculatePriceOfTokens(
        tierMax,
        singlePoolData.tokenDetails.tokenPrice
      );

      // console.log(tierMax);

      // tierMax = convertNegetiveNumberToNumber(tierMax);

      userPurchased.amount = calculatePriceOfTokens(
        userPurchased.amount,
        singlePoolData.tokenDetails.tokenPrice
      );

      setMaxAmount(tierMax);

      let allocationLefttokenValue =
        parseFloat(tierMax) - parseFloat(userPurchased.amount);

      allocationLefttokenValue = parseInt(allocationLefttokenValue);

      // setAllocationLeft(allocationLefttokenValue);
      setAllocationLeft(Math.ceil(actualAllocationleft));
    };

    fetchData();
  }, [projectContract.tier, singlePoolData]);

  const setMaxUserCanInvest = () => {
    setAmount(parseInt(userMaxAmountBuy));
    setUserRecieveValue(
      parseFloat(
        parseFloat(userMaxAmountBuy) /
          parseFloat(singlePoolData.tokenDetails.tokenPrice)
      ).toFixed(2)
    );
  };

  useEffect(() => {
    if (amount === "") {
      setUserRecieveValue(0);
    } else {
      setUserRecieveValue(
        parseFloat(amount) / parseFloat(singlePoolData.tokenDetails.tokenPrice)
      );
    }
  }, [amount, singlePoolData.tokenDetails.tokenPrice]);

  const submitJoinPool = async (e) => {
    e.preventDefault();
    if (isNaN(amount)) {
      toast.info("Please enter a valid number");
      return;
    }

    // if (amount.toString().includes(".")) {
    //   toast.info("Please enter a whole number");
    //   return;
    // }

    // let userAmount = parseFloat(amount);

    if (parseFloat(amount) <= 0) {
      toast.info("Amount cannot be 0 or less than 0");
      return;
    }

    if (parseFloat(amount) > parseFloat(allocationLeft)) {
      toast.info("Amount cannot be more than the Allocation left");
      return;
    }

    // else if (parseFloat(amount) > parseFloat(maxAmount)) {
    //   toast.info("Amount cannot be more than the max amount");
    // }

    /*  else if (parseFloat(amount) > userBalance) {
      toast.info("You do not have any tokens to join the pool");
    } */
    else {
      // let networkId = await networkID();

      // if (
      //   parseFloat(networkId) !==
      //   parseFloat(singlePoolData.projectNetwork.networkId)
      // ) {
      //   toast.info(
      //     `Please connect to ${singlePoolData.projectNetwork.networkName}`
      //   );
      //   return;
      // }

      const { account } = await fetchAccountDetails();
      const buyAddress = account.address;
      let addressBelongsToTier = await getAddressBelongsToTier(buyAddress);

      if (parseFloat(addressBelongsToTier) === 0) {
        toast.info("You have not been whitelisted for any tier");
        return;
      }

      // if (
      //   parseFloat(addressBelongsToTier) !== parseFloat(projectContract.tier)
      // ) {
      //   toast.info(
      //     `You have been whitelisted for tier ${addressBelongsToTier}`
      //   );
      //   return;
      // }

      // if(buyAddress)
      // console.log(networkID);

      setJoinPoolLoading(true);
      let web3Instance = new web3Class(singlePoolData);

      let userEnteredAmount = amount;

      let tierMax = await web3Instance.getTierMaxAmountThatCanBeInvested(
        projectContract.tier || "0"
      );

      tierMax = calculatePriceOfTokens(
        tierMax,
        singlePoolData.tokenDetails.tokenPrice
      );

      // if (tierMax === parseFloat(amount)) {
      //   userEnteredAmount = parseFloat(amount);
      // } else {
      //   userEnteredAmount = parseFloat(amount) - 0.01;
      // }

      try {
        await buyPoolToken(
          userEnteredAmount,
          singlePoolData.projectContractAddress,
          singlePoolData.tokenDetails.tokenPrice
        );
        toast.success("Tokens Successfully Bought");
        if (isSocketConnected) {
          // console.log("first");
          socket.emit("project-progress", {
            projectId: singlePoolData._id,
            tier: currentBuyingTier,
          });
          // console.log("second");
          // socket.emit("all-project-progress");
        }
        // window.location.reload();
      } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong");
      }
      props.toggle_Modal({ show: false });
      setJoinPoolLoading(false);
    }
  };

  return (
    <form onSubmit={submitJoinPool} className="joinpool">
      <div className="avatar">
        <img
          src={singlePoolData.projectLogoUrl}
          className="avatar-img"
          alt={singlePoolData.projectName}
        />
        <NetworkImg 
          networkId={singlePoolData.projectNetwork.networkId}
          networkName={singlePoolData.projectNetwork.networkName}
          className="network"
        />
        {/* <img
          src={singlePoolData.projectNetwork.networkImageUrl}
          alt={singlePoolData.projectName}
          className="network"
        /> */}
      </div>
      <h1 className="heading">{`Join ${projectContract.projectName} Pool`}</h1>
      <div className="balance text-center">
        {/* {`Wallet Balance: $${userCurrencyBalance}  ${singlePoolData.supportedToken}`} */}
        {/* <div>{`Max Allocations: $${maxAmount} BUSD`}</div> */}
        <div>
          Allocation Left:{" "}
          <span>{` $${allocationLeft} Left out of $${maxAmount}`}</span>
        </div>
      </div>
      <div className="join-wrap">
        {/* <div className="max-amount">
          <p>{`Max ${userMaxAmountBuy} Tokens that can be purchased`}</p>
          <button type="button" onClick={setMaxUserCanInvest} className="max">
            MAX
          </button>
        </div> */}

        <div className="amount-wrapper">
          {singlePoolData.supportedToken.toLowerCase() === "busd" && (
            <img src={USDSVG} alt="BUSD" className="token-img" />
          )}
          {singlePoolData.supportedToken.toLowerCase() === "bnb" && (
            <img src={bnbImg} alt="bnb" className="token-img" />
          )}
          {singlePoolData.supportedToken.toLowerCase() === "usdc" && (
            <img src={usdcImg} alt="usdc" className="token-img" />
          )}
          {singlePoolData.supportedToken.toLowerCase() === "usdt" && (
            <img src={usdtImg} alt="usdt" className="token-img" />
          )}
          {singlePoolData.supportedToken.toLowerCase() === "avax" && (
            <img src={avaxImg} alt="avax" className="token-img" />
          )}
          {singlePoolData.supportedToken.toLowerCase() === "matic" && (
            <img src={maticImg} alt="matic" className="token-img" />
          )}
          {singlePoolData.supportedToken.toLowerCase() === "frax" && (
            <img src={fraxImg} alt="frax" className="token-img" />
          )}

          <input
            type="text"
            onChange={(e) => {
              if (e.target.value === "") {
                setUserRecieveValue(0);
                setAmount("");
              } else {
                setAmount(e.target.value);
              }
            }}
            value={amount}
            className="amount-wrapper-input"
            placeholder="Enter Amount"
          />
          <p className="tokan-curr">
            <button type="button" onClick={setMaxUserCanInvest} className="max">
              MAX
            </button>
          </p>
        </div>
        <div className="max-amount">
          <div className="recive-amount">
            <p>You will Receive</p>
            <p>
              {parseFloat(userRecieveValue).toFixed(2)}{" "}
              {singlePoolData.tokenDetails.tokenSymbol}
            </p>
          </div>
          <div className="recive-amount">
            <p>Conversion Rate</p>
            <p>{`${singlePoolData.tokenDetails.rate}`}</p>
          </div>
        </div>
      </div>
      <div className="wallet-balance text-center">
        {`Wallet Balance: $${userCurrencyBalance}  ${singlePoolData.supportedToken}`}
      </div>
      <button
        type="submit"
        className="joinpool-submit"
        disabled={joinPoolLoading || loading}
      >
        {joinPoolLoading ? "Processing..." : "Submit"}
      </button>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    projectContract: state.reducer.projectContract || {},
    singlePoolData: state.reducer.singlePoolData || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_Modal: (show) => dispatch(toggleModal(show)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinPool);
