import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  setProjectContract,
  toggleModal,
} from "../../../../store/actions/action";

import {
  fetchAccountDetails,
  getAddressTier,
  getTierData,
} from "../../../../Web3/web3";
import "../PoolDetails/PoolDetails.scss";
// import PoolDetailsProgressFcfs from "../PoolDetailsProgress/PoolDetailsProgressFcfs";
// import { calculateTotalAvailableTokensInAllTiers } from "../../../../utils/PoolInteraction";
import PoolDetailsProgress from "../PoolDetailsProgress/PoolDetailsProgress";
import { web3Class } from "../../../../Web3/web3Class";
import {
  calculatePriceOfTokens,
  calculatePriceOfTokensRoundUp,
} from "../../../../utils/helpers";

const EndedPoolDetails = (props) => {
  const { singlePoolData } = props;

  const openJoinPool = async (tier) => {
    let accountDetails = await fetchAccountDetails();
    let account = accountDetails.account.address;
    if (account.length < 0) {
      return;
    }

    props.toggle_Modal({
      show: true,
      modalType: "claimFromPool",
      modalName: "Claim From Pool",
    });

    props.setProjectContractDetails({
      //   contractAddress: projectContractAddress,
      tier: tier,
      name: singlePoolData.projectName,
    });
  };

  const PoolSize = ({ tier }) => {
    const [poolSize, setPoolSize] = useState(0);

    useEffect(() => {
      const getData = async () => {
        let web3Instance = await new web3Class(singlePoolData);

        // console.log(singlePoolData.tokenDetails.decimals);

        // web3Instance.showAddress();
        let tierData = await web3Instance.getTierData(tier);

        tierData = calculatePriceOfTokensRoundUp(
          tierData,
          singlePoolData.tokenDetails.tokenPrice
        );
        setPoolSize(tierData);
      };

      getData();
    }, [tier]);

    return poolSize;
  };

  // const PoolSizeFcfs = () => {
  //   const [poolSize, setPoolSize] = useState(0);

  //   useEffect(async () => {
  //     let tierData = await calculateTotalAvailableTokensInAllTiers();
  //     setPoolSize(tierData);
  //   }, []);

  //   return poolSize;
  // };

  const PoolValue = ({ tier }) => {
    const [poolSize, setPoolSize] = useState(0);

    useEffect(() => {
      const getData = async () => {
        let web3Instance = new web3Class(singlePoolData);

        let tierData = await web3Instance.getTierMaxAmountThatCanBeInvested(
          tier
        );

        tierData = calculatePriceOfTokensRoundUp(
          tierData,
          singlePoolData.tokenDetails.tokenPrice
        );

        setPoolSize(tierData);
      };
      getData();
    }, [tier]);

    if (poolSize === 0) {
      return <>---</>;
    }
    return poolSize;
  };

  return (
    <>
      {singlePoolData.typeOfProject === "Community IDO" ? (
        <div
          className="pool__grid"
          style={{
            gridTemplateColumns: `${
              singlePoolData.projectStatus === "ended"
                ? "repeat(4, 1fr)"
                : "repeat(5, 1fr)"
            }`,
          }}
        >
          <div className="pool-tier">Community Pool</div>
          <div className="pool-size">
            $<PoolSize tier="4" />
          </div>
          <div className="pool-value">
            $<PoolValue tier="4" />
          </div>
          <div
            className="pool-progress"
            style={{
               paddingLeft: "25px",
            }}
          >
            {/* <PoolDetailsProgress tier={4} singlePoolData={singlePoolData} /> */}
            ENDED
          </div>
          {/* <div className="pool-action">
            <button className="active" onClick={() => openJoinPool(4)}>
              Claim
            </button>
            ---
          </div> */}
        </div>
      ) : (
        <>
          <div
            className="pool__grid"
            style={{
              gridTemplateColumns: `${
                singlePoolData.projectStatus === "ended"
                  ? "repeat(4, 1fr)"
                  : "repeat(5, 1fr)"
              }`,
            }}
          >
            <div className="pool-tier">Moon</div>
            <div className="pool-size">
              $<PoolSize tier="1" />
            </div>
            <div className="pool-value">
              $<PoolValue tier="1" />
            </div>
            <div
              className="pool-progress"
              style={{
                 paddingLeft: "25px",
              }}
            >
              {/* <PoolDetailsProgress tier={1} singlePoolData={singlePoolData} /> */}
              ENDED
            </div>
            {/* <div className="pool-action">
              <button className="active" onClick={openJoinPool}>
                Claim
              </button>
              ---
            </div> */}
          </div>

          <div
            className="pool__grid"
            style={{
              gridTemplateColumns: `${
                singlePoolData.projectStatus === "ended"
                  ? "repeat(4, 1fr)"
                  : "repeat(5, 1fr)"
              }`,
            }}
          >
            <div className="pool-tier">Sun</div>
            <div className="pool-size">
              $<PoolSize tier="2" />
            </div>
            <div className="pool-value">
              $<PoolValue tier="2" />
            </div>
            <div
              className="pool-progress"
              style={{
                 paddingLeft: "25px",
              }}
            >
              {/* <PoolDetailsProgress tier={2} singlePoolData={singlePoolData} /> */}
              ENDED
            </div>
            {/* <div className="pool-action">
              <button className="active" onClick={openJoinPool}>
                Claim
              </button>
              ---
            </div> */}
          </div>

          <div
            className="pool__grid"
            style={{
              gridTemplateColumns: `${
                singlePoolData.projectStatus === "ended"
                  ? "repeat(4, 1fr)"
                  : "repeat(5, 1fr)"
              }`,
            }}
          >
            <div className="pool-tier">Galaxy</div>
            <div className="pool-size">
              $<PoolSize tier="3" />
            </div>
            <div className="pool-value">
              $<PoolValue tier="3" />
            </div>
            <div
              className="pool-progress"
              style={{
                 paddingLeft: "25px",
              }}
            >
              {/* <PoolDetailsProgress tier={3} singlePoolData={singlePoolData} /> */}
              ENDED
            </div>
            {/* <div className="pool-action">
              <button className="active" onClick={openJoinPool}>
                Claim
              </button>
              ---
            </div> */}
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_Modal: (show) => dispatch(toggleModal(show)),
    setProjectContractDetails: (data) => dispatch(setProjectContract(data)),
  };
};

export default connect(null, mapDispatchToProps)(EndedPoolDetails);
