import { Form, Col } from "react-bootstrap";
import React, { useEffect, useReducer, useState } from "react";
import api from "../../../config/axios-config";
import { Buttons } from "../UI/Buttons";
import "./Form.css";
import { checkConnectionNetwork } from "../../../Web3/web3";
import { useLocation } from "react-router";
import CountryList from "./Countries.json";
import { toggleModal } from "../../../store/actions/action";
import { connect } from "react-redux";
import { toast } from "react-toastify";


const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  };
};

const UserForm = (props) => {
  let location = useLocation();
  const [accountAddress, setAccountAddress] = useState("");

  const [formData, setFormData] = useReducer(formReducer, {
    walletAddress: props.address,
    projectId: props.projectId || null,
  });

  useEffect(async () => {
    let { account } = await checkConnectionNetwork();
    let { address } = account;
    setAccountAddress(address);
  }, []);

  const handleChange = (e) => {
    setFormData({
      name: e.target.name,
      value: e.target.value,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    let userData = formData;
    userData["projectId"] = location.pathname.split("/")[2];
    if (userData["dob"].length < 2) {
      toast.error("Please enter Date of Birth");
      return;
    }
    if (accountAddress.length < 2) {
      toast.error("Please connect to MetaMask Wallet");
      return;
    }
    userData["walletAddress"] = accountAddress;
    userData["dob"] = new Date(userData["dob"]);
    userData["stakedAmount"] = parseFloat(userData["stakedAmount"]);
    // stakedAm0ount
    api
      .post("users/registration", { data: userData })
      .then((data) => {
        console.log(data);
        toast.success("Project Successfully registered");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something Went Wrong");
      });
    props.toggle_Modal({ show: false });

    // console.log(userData);
  };
  return (
    <Form onSubmit={submitForm}>
      <Form.Row>
        <Form.Group as={Col} controlId="firstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            name="firstName"
            type="text"
            placeholder="Your First Name"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="secondName">
          <Form.Label>Second Name</Form.Label>
          <Form.Control
            name="secondName"
            type="text"
            placeholder="Your Second Name"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="lastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            name="lastName"
            type="text"
            placeholder="Your Last Name"
            onChange={handleChange}
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="countryOfResidence">
          <Form.Label>Country of residence</Form.Label>
          {/* <Form.Control
            name="countryOfResidence"
            type="text"
            placeholder="Country of residence"
            onChange={handleChange}
          /> */}
          <select
            id="countryOfResidence"
            name="countryOfResidence"
            onChange={handleChange}
            className="form-control"
            placeholder="Country of residence"
            required
          >
            <option disabled>Please Select</option>
            {CountryList.map((country) => (
              <option key={country.name} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
        </Form.Group>
        <Form.Group as={Col} controlId="identityProof">
          <Form.Label>Identity Proof</Form.Label>
          <Form.Control
            name="identityProof"
            type="text"
            placeholder="Identity Proof"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="stakedAmount">
          <Form.Label>Staked amount</Form.Label>
          <Form.Control
            name="stakedAmount"
            type="number"
            placeholder="Staked amount"
            onChange={handleChange}
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="emailId">
          <Form.Label>Email Id</Form.Label>
          <Form.Control
            name="emailId"
            type="email"
            placeholder="Email Id"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="dob">
          <Form.Label>Date Of Birth</Form.Label>
          <Form.Control
            name="dob"
            type="date"
            placeholder="Date Of Birth"
            onChange={handleChange}
            required
          />
        </Form.Group>
        {/* <Form.Group as={Col} controlId="qualifiedForTiers">
          <Form.Label>Qualified for tier ?</Form.Label>
          <Form.Control
            name="qualifiedForTiers"
            type="text"
            placeholder="Qualified for tier ?"
            onChange={handleChange}
          />
        </Form.Group> */}
        <Form.Group className="mb-3" as={Col} controlId="qualifiedForTiers">
          <Form.Label>Qualified for tier ?</Form.Label>
          <select
            id="qualifiedForTiers"
            name="qualifiedForTiers"
            onChange={handleChange}
            className="form-control"
            placeholder="Qualified for tier ?"
            required
          >
            <option disabled>Please Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} sm={4} controlId="walletAddress">
          <Form.Label>Wallet address</Form.Label>
          <Form.Control
            name="walletAddress"
            type="text"
            disabled
            placeholder="Wallet address"
            onChange={handleChange}
            // defaultValue={accountAddress}
            value={accountAddress}
          />
        </Form.Group>
        <Form.Group as={Col} sm={8} controlId="previousParticipations">
          <Form.Label>Previous participation in projects</Form.Label>
          {/* <Form.Control
            name="previousParticipations"
            type="text"
            placeholder="Previous participation in projects"
            onChange={handleChange}
          /> */}
          <select
            id="previousParticipations"
            name="previousParticipations"
            onChange={handleChange}
            className="form-control"
            placeholder="Previous participation in projects"
            required
          >
            <option disabled>Please Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </Form.Group>
      </Form.Row>
      <Buttons
        btnName={"Submit"}
        className={"submit-btn"}
        modal={{ isModal: false }}
        type={"submit"}
      ></Buttons>
    </Form>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_Modal: (show) => dispatch(toggleModal(show)),
  };
};

export default connect(null, mapDispatchToProps)(UserForm);
// export default UserForm;
