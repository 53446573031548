import * as actions from "./actionTypes";
import { initiateConnection } from "../../Web3/web3";
import { toast } from "react-toastify";

export const isMetaMaskInstalled = () => {
  return (dispatch) => {
    return initiateConnection()
      .then((data) => {
        // toast.success("", data.notification.message);
        return dispatch(connectMetamask(data));
      })
      .catch((error) => {
        toast.error("", error.notification.message);
      });
  };
};

export const connectMetamask = (data) => ({
  type: actions.CONNECT_METAMASK,
  payload: {
    data,
  },
});

export const accountDetails = () => ({
  type: actions.ACCOUNT_DETAILS,
  payload: {},
});

export const transactionDetails = () => ({
  type: actions.TRANSACTION_DETAILS,
  payload: {},
});

export const addressWhitelisting = () => ({
  type: actions.ADDRESS_WHITELISTING,
  payload: {},
});

export const disconnetMetamask = () => ({
  type: actions.DISCONNECT_METAMASK,
  payload: {},
});

export const toggleModal = (data) => ({
  type: actions.TOGGLE_MODAL,
  payload: {
    data,
  },
});

export const updateProjectTab = (data) => ({
  type: actions.PROJECT_TABS,
  payload: {
    data,
  },
});

export const notification = () => ({
  type: actions.NOTIFICATIONS,
  payload: {},
});

export const setProjectContract = (data) => ({
  type: actions.SET_PROJECT_CONTRACT,
  payload: {
    data,
  },
});

export const setSinglePoolDetails = (data) => ({
  type: actions.SET_SINGLE_POOL_DETAILS,
  payload: {
    data,
  },
});

export const initializeSocketConnection = (data) => ({
  type: actions.INITIALIZE_SOCKET,
  payload: {
    data,
  },
});
