import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/metalaunch.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { isMetaMaskInstalled, toggleModal } from "../../store/actions/action";
import CustomModal from "../shared/Modal";
import { Modal } from "react-bootstrap";
import { checkMetaMaskInstalled, getAccountBalance } from "../../Web3/web3";
import { useAccountChange, useNetworkChange } from "../../hooks";
import "./navbar.scss";

import polygon from "./assets/polygon.png";
import bsc from "./assets/bsc.png";
import avax from "./assets/avax.png";
import wallet from "./assets/wallet.png";
import api from "../../config/apiCall";
import asva_logo from "./assets/asva-logo.png";

// import history from '../../history';

// import { useAccountChange } from "../../hooks";

const Navbar = (props) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [networkChainId, setNetworkChainId] = useState(undefined);
  const [userBalance, setuserBalance] = useState(0);
  const [kycStatus, setKycStatus] = useState("verify");
  const [isWrongNetwork, setIsWrongNetwork] = useState(false);

  // http://qa-admin-api.metalaunch.io/kyc/fetchUserStatus/0x30629E1c744Ad99A18D918F6AEa074bf27249098

  let location = useLocation();

  let network = useNetworkChange();
  let account = useAccountChange();

  useEffect(async () => {
    if (account === undefined) {
      setShowAlert(true);
      setShowAlertMessage("Please connect Metamask");
      setNetworkChainId(undefined);
      setUserAddress("");
    } else {
      setShowAlert(false);
      setShowAlertMessage("");
      let accountShort =
        account.substring(0, 4) +
        "..." +
        account.substring(account.length - 4, account.length);
      setUserAddress(accountShort);
      let balance = await getAccountBalance();

      // console.log(accountBalance);
      setuserBalance(parseFloat(balance).toFixed(2));
      // console.log(balance);
      setNetworkChainId(network);

      // Polygon  Testnet 80001

      if (network === 137) {
        setIsWrongNetwork(false);
      } else if (network === 56) {
        setIsWrongNetwork(false);
      } else if (network === 43114) {
        setIsWrongNetwork(false);
      } else {
        setIsWrongNetwork(true);
      }
    }

    let projectNetwork = props?.singlePoolData?.projectNetwork?.networkId;

    if (projectNetwork !== undefined) {
      if (parseFloat(projectNetwork) !== parseFloat(network)) {
        setShowAlert(true);
        setShowAlertMessage(
          "Please connect to " + props.singlePoolData.projectNetwork.networkName
        );
      } else {
        setShowAlert(false);
        setShowAlertMessage("");
      }
    }
  }, [network, account]);

  const getKycStatus = async () => {
    if (account !== undefined) {
      try {
        const { data } = await api.getReplace(`kyc/fetchUserStatus/${account}`);
        // console.log(data);
        setKycStatus(data.status);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getKycStatus();
  }, [account]);

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location.pathname]);

  const [showModal, setModalValue] = useState(false);

  const toggleModal = (value) => {
    setModalValue(value);
  };

  useEffect(async () => {
    let checkStatus = checkMetaMaskInstalled();

    if (checkStatus === false) {
      setShowAlert(true);
      setShowAlertMessage("Please Install MetaMask");
    }

    let projectNetwork = props?.singlePoolData?.projectNetwork?.networkId;

    if (network !== undefined && projectNetwork !== undefined) {
      if (parseFloat(projectNetwork) !== parseFloat(network)) {
        setShowAlert(true);
        setShowAlertMessage(
          "Please connect to " + props.singlePoolData.projectNetwork.networkName
        );
      } else {
        setShowAlert(false);
        setShowAlertMessage("");
      }
    }
  }, [props.singlePoolData]);

  const changeNetwork = () => {
    props.toggle_Modal({
      show: true,
      modalType: "connectWallet",
      modalName: "Connect Wallet",
    });
  };

  return (
    <>
      {props.modal.show && (
        <CustomModal
          modalDetails={{ name: props.modal.modalName }}
        ></CustomModal>
      )}

      {showAlert && (
        <div className="custom-alert">
          <p>{showAlertMessage}</p>
        </div>
      )}

      <div className="navbar">
        <div className="navbar__ullist_logo">
          <Link className="navbar__logo" to="/projects">
            <img className="logo" src={logo} />
          </Link>

          <ul className="navbar__ullist">
            <li className="navbar__item Stake">
              <a
                href="https://stake.metalaunch.io/"
                target="_blank"
                className="navbar__link"
              >
                Stake
              </a>

              {/* <button
              className="buy__asva"
              onClick={() => {
                window.open("https://app.mantradao.com/staking");
              }}
            >
              Stake
            </button> */}
            </li>

            <li className="navbar__item">
              <Link to="/projects" className="navbar__link">
                Pool
              </Link>
            </li>
            <li className="navbar__item">
              <a
                href="https://asvalabs.medium.com/how-to-participate-in-asva-ido-on-metalaunch-cedc9bcecff9"
                target="_blank"
                className="navbar__link"
                rel="noreferrer"
              >
                Support
              </a>
            </li>
          </ul>
        </div>

        <ul className="navbar__list">
          <li className="navbar__item">
            <a
              className="navbar__icon_buy_asva"
              href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0xf7b6d7e3434cb9441982f9534e6998c43eef144a"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="asva_icon"
                src={asva_logo}
                alt="asva_logo"
                height="36px"
                width=""
              />

              <a
                className="buy__asva"
                href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0xf7b6d7e3434cb9441982f9534e6998c43eef144a"
                target="_blank"
                rel="noreferrer"
              >
                Buy ASVA
              </a>
            </a>
          </li>

          {networkChainId === 137 && (
            <li className="navbar__item">
              <div className="networkWrapper polygon" onClick={changeNetwork}>
                <img className="icon" src={polygon} alt="polygon" />
                <p className="name">
                  Polygon <span>{userBalance}</span>
                </p>
              </div>
            </li>
          )}

          {networkChainId === 56 && (
            <li className="navbar__item">
              <div className="networkWrapper bsc" onClick={changeNetwork}>
                <img className="icon" src={bsc} alt="bsc" />
                <p className="name">
                  BSC <span>{userBalance}</span>{" "}
                </p>
              </div>
            </li>
          )}
          {networkChainId === 43114 && (
            <li className="navbar__item">
              <div className="networkWrapper avax" onClick={changeNetwork}>
                <img className="icon" src={avax} alt="avax" />
                <p className="name">
                  Avalanche <span>{userBalance}</span>{" "}
                </p>
              </div>
            </li>
          )}

          {isWrongNetwork && (
            <li className="navbar__item">
              <div className="networkWrapper" onClick={changeNetwork}>
                <p className="name">Wrong Network</p>
              </div>
            </li>
          )}

          {account === undefined ? (
            <li className="navbar__item">
              <button
                className="connectWalletBtn"
                onClick={() => props.connectMetamask()}
              >
                <img className="icon" src={wallet} alt="wallet" />
                <p className="name">Connect Wallet</p>
              </button>
            </li>
          ) : (
            <li className="navbar__item">
              <button className="connectWalletBtn">
                {/* <img className="icon" src={metamask} alt="metamask" /> */}
                <p className="name">
                  {userAddress}{" "}
                  {kycStatus === "verify" && (
                    <button
                      className="verify-btn"
                      onClick={() =>
                        window.open(
                          "https://verify-with.blockpass.org/?clientId=asva_ido_on_metalaunch_cce82&serviceName=Asva%20IDO%20on%20MetaLaunch&env=prod"
                        )
                      }
                    >
                      Verify KYC
                    </button>
                  )}
                  {kycStatus === "waiting" && (
                    <button
                      className="submitted-btn"
                      onClick={() =>
                        window.open(
                          "https://verify-with.blockpass.org/?clientId=asva_ido_on_metalaunch_cce82&serviceName=Asva%20IDO%20on%20MetaLaunch&env=prod"
                        )
                      }
                    >
                      Submitted
                    </button>
                  )}
                  {kycStatus === "reviewing" && (
                    <button
                      className="submitted-btn"
                      onClick={() =>
                        window.open(
                          "https://verify-with.blockpass.org/?clientId=asva_ido_on_metalaunch_cce82&serviceName=Asva%20IDO%20on%20MetaLaunch&env=prod"
                        )
                      }
                    >
                      Submitted
                    </button>
                  )}
                  {kycStatus === "reverify" && (
                    <button
                      className="verify-btn"
                      onClick={() =>
                        window.open(
                          "https://verify-with.blockpass.org/?clientId=asva_ido_on_metalaunch_cce82&serviceName=Asva%20IDO%20on%20MetaLaunch&env=prod"
                        )
                      }
                    >
                      Reverify KYC
                    </button>
                  )}
                  {kycStatus === "approved" && (
                    <button className="verified-btn">Verified</button>
                  )}
                </p>
              </button>
            </li>
          )}
        </ul>
      </div>
      <Modal
        show={showModal}
        onHide={() => toggleModal(false)}
        backdrop="static"
        keyboard={false}
        animation={true}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="navBtnSmall">
            <Link
              to="/projects"
              className="navbar_link_sm"
              onClick={() => toggleModal(false)}
            >
              Projects
            </Link>
            <a
              href="https://forms.gle/RqQRypKBj9R86m4K7"
              target="_blank"
              className="navbar_link_sm activeLink"
            >
              Asva Warriors
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.reducer.account || {},
    connection: state.reducer.connection || {},
    modal: state.reducer.modal || {},
    singlePoolData: state.reducer.singlePoolData || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    connectMetamask: () => dispatch(isMetaMaskInstalled()),
    toggle_Modal: (show) => dispatch(toggleModal(show)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
