import POLYGONTESTNETABI from "./DEV_CURRENCY_ABI.json";
import USDCCURRENCYABI from "./USDC_CURRENCY.json";
import POOLABI from "./TierIDOPool.json";

export const web3Configuration = {
  // BSC Mainnet
  56: {
    abi: POOLABI,
    currency: {
      address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      abi: USDCCURRENCYABI,
    },
    rpc: "https://shy-nameless-sun.bsc.quiknode.pro/857077e9f117da1a6a57a981827335db71baadc9/",
  },
  // Polygon/Matic Mainnet
  137: {
    rpc: "https://matic.getblock.io/mainnet/?api_key=11e35fe6-558a-4850-b56c-7e6cf1ea27ff",
  },
  // Avalance Mainnet
  43114: {
    rpc: "https://speedy-nodes-nyc.moralis.io/31649378acd900255d51e632/avalanche/mainnet",
  },
  // BSC Testnet
  97: {
    abi: POOLABI,
    currency: "",
    // rpc: "https://bsc.getblock.io/testnet/?api_key=11e35fe6-558a-4850-b56c-7e6cf1ea27ff",
    rpc: "https://speedy-nodes-nyc.moralis.io/31649378acd900255d51e632/bsc/testnet",
  },
  // Polygon / Matic Testnet
  80001: {
    abi: POOLABI,
    currency: {
      address: "0x6e417ED32794D24a2e188d734B5b0747cD24DFE8",
      abi: POLYGONTESTNETABI,
    },
    rpc: "https://matic.getblock.io/testnet/?api_key=11e35fe6-558a-4850-b56c-7e6cf1ea27ff",
    // rpc: "http://qa-admin-api.metalaunch.io/maticTestnet",
  },

  1: {
    rpc: "https://speedy-nodes-nyc.moralis.io/31649378acd900255d51e632/avalanche/testnet",
  },

  43113: {
    rpc: "https://speedy-nodes-nyc.moralis.io/31649378acd900255d51e632/avalanche/testnet",
  },
};
