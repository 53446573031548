/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";

import {
  setProjectContract,
  toggleModal,
} from "../../../../store/actions/action";
import { toast } from "react-toastify";

import {
  fetchAccountDetails,
  getAddressTier,
  getTierData,
  isPoolActive,
} from "../../../../Web3/web3";

import PoolDetailsProgress from "../PoolDetailsProgress/PoolDetailsProgress";
import Loader from "../../../../components/Loader/Loader";
import PoolDetailsProgressFcfs from "../PoolDetailsProgress/PoolDetailsProgressFcfs";

import "../PoolDetails/PoolDetails.scss";
import { web3Class } from "../../../../Web3/web3Class";
import { useAccountChange, useNetworkChange } from "../../../../hooks";
import api from "../../../../config/apiCall";
import {
  calculatePriceOfTokens,
  calculatePriceOfTokensRoundDown,
  calculatePriceOfTokensRoundUp,
} from "../../../../utils/helpers";
import PoolSaleStatus from "./Components/PoolSaleStatus";

const ActivePoolDetails = (props) => {
  const { singlePoolData } = props;
  let account = useAccountChange();
  let network = useNetworkChange();
  const [loading, setLoading] = useState(false);
  const [poolDetailsLoading, setPoolDetailsLoading] = useState(true);
  const [isFcfs, setIsFcfs] = useState(false);
  const [isJoinPoolDisabled, setIsJoinPoolDisabled] = useState({
    tier1: true,
    tier2: true,
    tier3: true,
    tier4: true,
    fcfs: true,
  });
  const [userAddressTier, setUserAddressTier] = useState(0);

  // console.log("TPPPPP", tierPercentage);

  const checkJoinPoolDetails = async () => {
    if (account !== undefined) {
      // const contractDetails = {
      //   contractAddress: singlePoolData.projectContractAddress,
      //   networkId: singlePoolData.projectNetwork.networkId,
      //   currencyAbi: singlePoolData.abi.currencyABI,
      //   tokenAbi: singlePoolData.abi.tokenABI,
      //   currencyAddress: singlePoolData.smartContractAddress.currency,
      //   tokenAddress: singlePoolData.smartContractAddress.token,
      // };
      let web3Instance = new web3Class(singlePoolData);

      let tierData = await web3Instance.getAddressBelongsToTier(account);

      tierData = parseFloat(tierData);
      // console.log(tierData);

      let projectId = location.pathname.split("/")[2];
      let walletAddress = account;
      let { data } = await api.getReplace(
        "projects/client/v1/getCurrentDateTime"
      );
      const timestampFromServer = new Date(data.date).toISOString();
      if (
        timestampFromServer > singlePoolData.idoDates.roundOneEndDate &&
        timestampFromServer < singlePoolData.idoDates.roundTwoStartDate
      ) {
        // toast.info("Please wait for FCFS round to begin");
        return;
      }

      let checkUniqueWalletAddress = await api.getReplace(
        `users/checkUserProjectStatus/${projectId}/${walletAddress}`
      );

      if (checkUniqueWalletAddress.data.data === true) {
        if (tierData === 1) {
          setIsJoinPoolDisabled({
            ...isJoinPoolDisabled,
            tier1: false,
            fcfs: false,
          });
        } else if (tierData === 2) {
          setIsJoinPoolDisabled({
            ...isJoinPoolDisabled,
            tier2: false,
            fcfs: false,
          });
        } else if (tierData === 3) {
          setIsJoinPoolDisabled({
            ...isJoinPoolDisabled,
            tier3: false,
            fcfs: false,
          });
        } else if (tierData === 4) {
          setIsJoinPoolDisabled({
            ...isJoinPoolDisabled,
            tier4: false,
            fcfs: false,
          });
        }
      }

      setUserAddressTier(parseFloat(tierData));
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      setPoolDetailsLoading(true);
      let checkPoolActive = await isPoolActive();

      if (checkPoolActive.current === "fcfs") {
        setIsFcfs(true);
      }

      if (account !== undefined) checkJoinPoolDetails();

      // setIsFcfs(true);
      setLoading(false);
      setPoolDetailsLoading(false);
    };

    getData();
  }, [account, network]);

  const openJoinPool = async (tier) => {
    let accountDetails = await fetchAccountDetails();
    let account = accountDetails.account.address;
    let { data } = await api.getReplace(
      "projects/client/v1/getCurrentDateTime"
    );
    const timestampFromServer = new Date(data.date).toISOString();
    if (account.length < 0) {
      return;
    }
    // if (account.length > 0) {
    //   let getUserTier = await getAddressTier();

    //   if (parseFloat(getUserTier) !== parseFloat(tier)) {
    //     toast.info("You have not been whitelisted for this tier");
    //     return;
    //   }
    // }

    // console.log(
    //   new Date().toISOString() > singlePoolData.idoDates.roundOneStartDate,
    //   new Date().toISOString() < singlePoolData.idoDates.roundTwoStartDate
    // );

    if (
      timestampFromServer > singlePoolData.idoDates.roundOneEndDate &&
      timestampFromServer < singlePoolData.idoDates.roundTwoStartDate
    ) {
      toast.info("Please wait for FCFS round to begin");
      return;
    }

    props.setProjectContractDetails({
      //   contractAddress: projectContractAddress,
      tier: tier,
      name: singlePoolData.projectName,
    });

    props.toggle_Modal({
      show: true,
      modalType: "joinPool",
      modalName: "Join Pool",
    });
  };

  const openJoinFcfsPool = async (tier) => {
    let accountDetails = await fetchAccountDetails();
    let account = accountDetails.account.address;
    if (account.length < 0) {
      return;
    }

    props.toggle_Modal({
      show: true,
      modalType: "joinFcfsPool",
      modalName: "Join Fcfs Pool",
    });

    props.setProjectContractDetails({
      //   contractAddress: projectContractAddress,
      tier: tier,
      name: singlePoolData.projectName,
    });
  };

  const PoolSize = ({ tier }) => {
    const [poolSize, setPoolSize] = useState(0);

    useEffect(() => {
      const getData = async () => {
        let web3Instance = new web3Class(singlePoolData);

        let tierData = await web3Instance.getTierData(tier);
        tierData = calculatePriceOfTokensRoundUp(
          tierData,
          singlePoolData.tokenDetails.tokenPrice
        );
        setPoolSize(tierData);
      };

      getData();
    }, []);

    return poolSize;
  };

  const PoolSizeFcfs = () => {
    const [poolSize, setPoolSize] = useState(0);
    const isSocketConnected = useSelector(
      (state) => state.reducer.socket.isConnected
    );
    const socket = useSelector((state) => state.reducer.socket.data);

    useEffect(() => {
      const getData = async () => {
        let web3Instance = new web3Class(singlePoolData);

        let tierData =
          await web3Instance.calculateTotalAvailableTokensInAllTiers();

        tierData = calculatePriceOfTokensRoundDown(
          tierData,
          singlePoolData.tokenDetails.tokenPrice
        );

        setPoolSize(tierData);
      };

      getData();
    }, []);

    useEffect(() => {
      if (isSocketConnected) {
        socket.on("project-progress", (projectData) => {
          // if (projectData.projectId === singlePoolData._id)
          //   setTierPercentage(projectData.overallPercentage);

          if (projectData.projectId === singlePoolData._id) {
            let availableTokens = projectData.availableTokens;

            availableTokens = calculatePriceOfTokensRoundDown(
              availableTokens,
              singlePoolData.tokenDetails.tokenPrice
            );
            setPoolSize(availableTokens);
          }
        });
      }
    }, [isSocketConnected, socket]);

    return poolSize;
  };

  const PoolValue = ({ tier }) => {
    const [poolSize, setPoolSize] = useState(0);

    useEffect(() => {
      const getData = async () => {
        let web3Instance = new web3Class(singlePoolData);

        let tierData = await web3Instance.getTierMaxAmountThatCanBeInvested(
          tier
        );

        tierData = calculatePriceOfTokensRoundUp(
          tierData,
          singlePoolData.tokenDetails.tokenPrice
        );

        setPoolSize(tierData);
      };
      getData();
    }, [tier]);

    if (poolSize === 0) {
      return <>---</>;
    }
    return poolSize;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {poolDetailsLoading ? (
        <Loader />
      ) : (
        <>
          {singlePoolData.typeOfProject === "Community IDO" ? (
            isFcfs ? (
              <div className="pool__grid">
                <div className="pool-tier">FCFS Pool</div>
                <div className="pool-size">
                  $<PoolSizeFcfs />
                </div>
                <div className="pool-value">
                  $<PoolSizeFcfs />
                </div>
                <div className="pool-progress">
                  <PoolDetailsProgressFcfs singlePoolData={singlePoolData} />
                </div>
                <div className="pool-action">
                  <PoolSaleStatus
                    tier={4}
                    singlePoolData={singlePoolData}
                    openJoinPool={openJoinFcfsPool}
                    isJoinPoolDisabled={isJoinPoolDisabled}
                    buttonDisabled={isJoinPoolDisabled.fcfs}
                  />
                  {/* {singlePoolData.soldOut === 100 ? (
                    `${soldout}`
                  ) : (
                    <button
                      className="active"
                      onClick={() => {
                        openJoinFcfsPool(4);
                      }}
                      disabled={isJoinPoolDisabled.fcfs}
                    >
                      Join Pool
                    </button>
                  )} */}
                </div>
              </div>
            ) : (
              <div className="pool__grid">
                <div className="pool-tier">Community Pool</div>
                <div className="pool-size">
                  $<PoolSize tier="4" />
                </div>
                <div className="pool-value">
                  $<PoolValue tier="4" />
                </div>
                <div className="pool-progress">
                  {poolDetailsLoading ? null : (
                    <PoolDetailsProgress
                      tier={4}
                      singlePoolData={singlePoolData}
                    />
                  )}
                </div>
                <div className="pool-action">
                  <PoolSaleStatus
                    tier={4}
                    singlePoolData={singlePoolData}
                    openJoinPool={openJoinPool}
                    isJoinPoolDisabled={isJoinPoolDisabled}
                    buttonDisabled={isJoinPoolDisabled.tier4}
                  />
                  {/* {singlePoolData.soldOut === 100 ? (
                    `${soldout}`
                  ) : (
                    <button
                      className="active"
                      onClick={() => openJoinPool(4)}
                      disabled={isJoinPoolDisabled.tier4}
                    >
                      Join Pool
                    </button>
                  )} */}
                </div>
              </div>
            )
          ) : isFcfs ? (
            <>
              <div className="pool__grid">
                <div className="pool-tier">FCFS Pool</div>
                <div className="pool-size">
                  $<PoolSizeFcfs />
                </div>
                <div className="pool-value">
                  $<PoolSizeFcfs />
                </div>
                <div className="pool-progress">
                  <PoolDetailsProgressFcfs singlePoolData={singlePoolData} />
                </div>
                <div className="pool-action">
                  <PoolSaleStatus
                    tier={4}
                    singlePoolData={singlePoolData}
                    openJoinPool={openJoinFcfsPool}
                    isJoinPoolDisabled={isJoinPoolDisabled}
                    buttonDisabled={isJoinPoolDisabled.fcfs}
                  />
                  {/* {singlePoolData.soldOut === 100 ? (
                    `${soldout}`
                  ) : (
                    <button
                      className="active"
                      onClick={() => {
                        openJoinFcfsPool(4);
                      }}
                      disabled={isJoinPoolDisabled.fcfs}
                    >
                      Join Pool
                    </button>
                  )} */}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="pool__grid">
                <div className="pool-tier">Moon</div>
                <div className="pool-size">
                  $<PoolSize tier="1" />
                </div>
                <div className="pool-value">
                  $<PoolValue tier="1" />
                </div>
                <div className="pool-progress">
                  {poolDetailsLoading ? null : (
                    <PoolDetailsProgress
                      tier={1}
                      singlePoolData={singlePoolData}
                    />
                  )}
                </div>
                <div className="pool-action">
                  <PoolSaleStatus
                    tier={1}
                    singlePoolData={singlePoolData}
                    openJoinPool={openJoinPool}
                    isJoinPoolDisabled={isJoinPoolDisabled}
                    buttonDisabled={isJoinPoolDisabled.tier1}
                  />
                  {/* {singlePoolData.soldOut === 100 ? (
                    `${soldout}`
                  ) : (
                    <button
                      className="active"
                      onClick={() => openJoinPool(1)}
                      disabled={isJoinPoolDisabled.tier1}
                    >
                      Join Pool
                    </button>
                  )} */}
                </div>
              </div>
              <div className="pool__grid">
                <div className="pool-tier">Sun</div>
                <div className="pool-size">
                  $<PoolSize tier="2" />
                </div>
                <div className="pool-value">
                  $<PoolValue tier="2" />
                </div>
                <div className="pool-progress">
                  {poolDetailsLoading ? null : (
                    <PoolDetailsProgress
                      tier={2}
                      singlePoolData={singlePoolData}
                    />
                  )}
                </div>
                <div className="pool-action">
                  <PoolSaleStatus
                    tier={2}
                    singlePoolData={singlePoolData}
                    openJoinPool={openJoinPool}
                    isJoinPoolDisabled={isJoinPoolDisabled}
                    buttonDisabled={isJoinPoolDisabled.tier2}
                  />
                  {/* {singlePoolData.soldOut === 100 ? (
                    `${soldout}`
                  ) : (
                    <button
                      className="active"
                      onClick={() => openJoinPool(2)}
                      disabled={isJoinPoolDisabled.tier2}
                    >
                      Join Pool
                    </button>
                  )} */}
                </div>
              </div>
              <div className="pool__grid">
                <div className="pool-tier">Galaxy</div>
                <div className="pool-size">
                  $<PoolSize tier="3" />
                </div>
                <div className="pool-value">
                  $<PoolValue tier="3" />
                </div>
                <div className="pool-progress">
                  {poolDetailsLoading ? null : (
                    <PoolDetailsProgress
                      tier={3}
                      singlePoolData={singlePoolData}
                    />
                  )}
                </div>
                <div className="pool-action">
                  <PoolSaleStatus
                    tier={3}
                    singlePoolData={singlePoolData}
                    openJoinPool={openJoinPool}
                    isJoinPoolDisabled={isJoinPoolDisabled}
                    buttonDisabled={isJoinPoolDisabled.tier3}
                  />
                  {/* {singlePoolData.soldOut === 100 ? (
                    `${soldout}`
                  ) : (
                    <button
                      className="active"
                      onClick={() => openJoinPool(3)}
                      disabled={isJoinPoolDisabled.tier3}
                    >
                      Join Pool
                    </button>
                  )} */}
                </div>
              </div>
              {/* {<div className="pool__grid">
                <div className="pool-tier">Moon</div>
                <div className="pool-size">---</div>
                <div className="pool-value">---</div>
                <div className="pool-progress">
                  
                  <span className="pool-progress-value">--</span>
                </div>
                <div className="pool-action">
                  <button className="active">Join Wishlist</button>
                </div>
              </div>} */}
            </>
          )}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_Modal: (show) => dispatch(toggleModal(show)),
    setProjectContractDetails: (data) => dispatch(setProjectContract(data)),
  };
};

export default connect(null, mapDispatchToProps)(ActivePoolDetails);
