import React, { useEffect } from "react";
import { connect } from "react-redux";
import { toggleModal } from "../../../../store/actions/action";
import ActivePoolDetails from "./ActivePoolDetails";
import EndedPoolDetails from "./EndedPoolDetails";
import UpcomingPoolDetails from "./UpcomingPoolDetails";
import "./PoolDetails.scss";

const PoolDetails = (props) => {
  const { singlePoolData } = props;

  // props.toggle_Modal({
  //   show: true,
  //   modalType: "joinPool",
  //   modalName: "Join Pool",
  // });

  // props.toggle_Modal({
  //   show: true,
  //   modalType: "joinWishlist",
  //   modalName: "Join Pool",
  // });

  const getData = async () => {
    // console.log("creating instance", singlePoolData.projectContractAddress);
    // await createContractInstance(singlePoolData.projectContractAddress);
  };

  useEffect(() => {
    getData();
  }, [props._id]);

  return (
    <div className="pool__wrapper">
      <div
        className="pool__grid header"
        style={{
          gridTemplateColumns: `${
            singlePoolData.projectStatus === "ended"
              ? "repeat(4, 1fr)"
              : "repeat(5, 1fr)"
          }`,
        }}
      >
        <div className="pool__grid--heading">TIER</div>
        <div className="pool__grid--heading">TIER ALLOCATION</div>
        <div className="pool__grid--heading">VALUE PER WINNER</div>
        <div className="pool__grid--heading">
          {singlePoolData.projectStatus === "ended"
            ? "SALE STATUS"
            : "PROGRESS"}
        </div>

        {singlePoolData.projectStatus !== "ended" &&
          (singlePoolData.soldOut == 100 ? (
            <div className="pool__grid--heading">SALE STATUS</div>
          ) : (
            <div className="pool__grid--heading">ACTION</div>
          ))}
      </div>
      {singlePoolData.projectStatus === "active" && (
        <ActivePoolDetails singlePoolData={singlePoolData} />
      )}
      {singlePoolData.projectStatus === "upcoming" && (
        <UpcomingPoolDetails singlePoolData={singlePoolData} />
      )}
      {singlePoolData.projectStatus === "ended" && (
        <EndedPoolDetails singlePoolData={singlePoolData} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_Modal: (show) => dispatch(toggleModal(show)),
  };
};

export default connect(null, mapDispatchToProps)(PoolDetails);
