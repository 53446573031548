import axios from "axios";

// const api = axios.create({
//     baseURL: 'http://35.175.46.153:3000'
// });

const api = axios.create({
  baseURL: "https://api.metalaunch.io/projects/client/v1/",
});

export default api;
