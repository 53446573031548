import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import api from "../../config/apiCall";
import { useAccountChange, useNetworkChange } from "../../hooks";
import { setSinglePoolDetails } from "../../store/actions/action";

import {
  createContractInstance,
  getChainId,
  switchNetwork,
  web3Initialise,
} from "../../Web3/web3";
import { web3Class } from "../../Web3/web3Class";
import PoolDetails from "./components/PoolDetails/PoolDetails";
import ProjectDetails from "./components/ProjectDetails/ProjectDetails";
import Sidebar from "./components/Sidebar/Sidebar";

import "./SinglePool.css";

const SinglePool = (props) => {
  const { setSinglePoolDetails } = props;
  const isSocketConnected = useSelector(
    (state) => state.reducer.socket.isConnected
  );
  const socket = useSelector((state) => state.reducer.socket.data);

  let network = useNetworkChange();
  let account = useAccountChange();

  let { id } = useParams();
  const [singlePoolData, setSinglePoolData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getData(id);
  }, [id, network, account]);

  // useEffect(() => {
  //   // socket.emit("connected", Math.random());
  //   // console.log("here")

  //   if (isSocketConnected) {
  //     // socket.emit("project-progress", {
  //     //   projectId: id,
  //     //   tier: 3,
  //     // });

  //     socket.on("project-progress", (projectData) => {
  //       console.log("projectData ", projectData);
  //       // console.log(projectData);

  //       // setData(projectData);
  //     });

  //     // socket.on("connected", (projectData) => {
  //     //   console.log("connected ", projectData);

  //     //   // setConnected(data);
  //     // });
  //   }
  // }, [socket, id, isSocketConnected]);

  // const getData = async (id) => {
  //   setLoading(true);
  //   try {
  //     const { data } = await api.post("projects/getProjectById", {
  //       id: id,
  //     });
  //     console.log(data.data);
  //     // Saving to local State
  //     setSinglePoolData(data.data);
  //     // Saving to Redux / Global State
  //     setSinglePoolDetails(data.data);
  //     console.log(data.data.projectContractAddress);

  //     await createContractInstance(data.data.projectContractAddress);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setLoading(false);
  //   // console.log(data);
  // };

  const getData = async (id) => {
    setLoading(true);
    try {
      const { data } = await api.postReplace(
        "projects/client/v1/getProjectById",
        {
          id: id,
        }
      );
      if (data.data.projectContractAddress) {
        let web3Instance = new web3Class(data.data);
        let percentage = await web3Instance.calculateOverallPoolPercentage();
        data.data.soldOut = percentage;
      }

      // Saving to local State
      setSinglePoolData(data.data);
      // Saving to Redux / Global State
      setSinglePoolDetails(data.data);

      // console.log(data.data);

      data.data.tokenDetails.decimals = parseFloat(data.data.currDecimal);

      // data.data.isIDOLauched = false;

      // data.data.tokenDetails.decimals = 6;

      let projectNetwork = data.data.projectNetwork.networkId;
      // console.log(data.data.abi,)
      if (projectNetwork !== network) {
        switchNetwork(projectNetwork);
      }

      // let idoDetails = {
      //   tokenAbi: data.data.abi.tokenABI,
      //   currencyAbi: data.data.abi.currencyABI,
      //   tokenAddress: data.data.smartContractAddress.token,
      //   currencyAddress: data.data.smartContractAddress.currency,
      //   networkId: projectNetwork,
      // };

      await web3Initialise(data.data);

      await createContractInstance(data.data.projectContractAddress);
      // web3Initialise();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    // console.log(data);
  };

  return (
    <div className="singlePoolBackground">
      <Container className={loading ? "" : "singlepool"}>
        <div className="singlepool__header">
          <h1 className="main-heading">
            <span className={`dot ${singlePoolData.projectStatus}`}></span>
            <span className="uppercase">
              {singlePoolData.projectStatus}
            </span>{" "}
            pool
          </h1>
          {/* <a href="/" className="go-home">
            Home/View Details
          </a> */}
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="singlepool__sidebar">
              <Sidebar singlePoolData={singlePoolData} />
            </div>
            <div className="singlepool__content">
              <PoolDetails singlePoolData={singlePoolData} />
              <ProjectDetails singlePoolData={singlePoolData} />
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSinglePoolDetails: (data) => dispatch(setSinglePoolDetails(data)),
  };
};

export default connect(null, mapDispatchToProps)(SinglePool);
