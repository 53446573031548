import React from "react";
import Spinner from "./spinner.gif";
const Loader = ({ width }) => {
  if (width === undefined) {
    width = "100px";
  }

  return (
    <div className="spinner">
      <img
        src={Spinner}
        alt="Spinner"
        style={{
          display: "block",
          margin: "0 auto",
          width: `${width}`,
        }}
      />
    </div>
  );
};

export default Loader;
