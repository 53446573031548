import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { connect, useSelector } from "react-redux";
import {
  buyPoolToken,
  checkSales,
  getCurrencyBalance,
  getTierMaxAmountThatCanBeInvested,
  getTokensAvailableInTier,
  networkID,
} from "../../../../Web3/web3";
import { web3Class } from "../../../../Web3/web3Class";
// import { calculateTotalAvailableTokensInAllTiers } from "../../../../utils/PhoolInteraction";
import { toggleModal } from "../../../../store/actions/action";
import { useAccountChange } from "../../../../hooks";
import Loader from "../../../Loader/Loader";
import "./JoinPool.scss";
import avaxImg from "./assets/avax.png";
import maticImg from "./assets/matic.svg";
import bnbImg from "./assets/bnb.png";
import fraxImg from "./assets/frax.png";
import usdcImg from "./assets/usdc.png";
import usdtImg from "./assets/usdt.png";
import USDCSVG from "./assets/usd.svg";
import {
  calculatePriceOfTokens,
  calculatePriceOfTokensRoundDown,
  convertNegetiveNumberToNumber,
} from "../../../../utils/helpers";
import NetworkImg from "../../..//NetworkImg/NetworkImg"

const JoinPoolFcfs = (props) => {
  let account = useAccountChange();
  const currentBuyingTier = useSelector(
    (state) => state.reducer.projectContract.tier
  );
  const isSocketConnected = useSelector(
    (state) => state.reducer.socket.isConnected
  );
  const socket = useSelector((state) => state.reducer.socket.data);
  const { projectContract, singlePoolData } = props;
  const [userCurrencyBalance, setUserCurrencyBalance] = useState("loading");
  const [allocationLeft, setAllocationLeft] = useState("loading...");
  const [userRecieveValue, setUserRecieveValue] = useState(0);
  const [maxAmount, setMaxAmount] = useState("loading...");
  const [amount, setAmount] = useState("");
  const [tokensAvailableInTier, setTokensAvailableInTier] = useState("loading");
  const [userMaxAmountBuy, setUserMaxAmountBuy] = useState("loading...");
  const [buyPoolLoading, setBuyPoolLoading] = useState(false);

  const fetchUserCurrencyBalance = async () => {
    let balance = await getCurrencyBalance();
    balance = Math.floor(balance);
    setUserCurrencyBalance(balance);
  };

  useEffect(() => {
    fetchUserCurrencyBalance();
  }, [account]);

  useEffect(() => {
    const fetchData = async () => {
      let web3Instance = new web3Class(singlePoolData);

      let tierMax =
        await web3Instance.calculateTotalAvailableTokensInAllTiers();

      let tierMax1 = calculatePriceOfTokensRoundDown(
        tierMax,
        singlePoolData.tokenDetails.tokenPrice
      );

      tierMax1 = convertNegetiveNumberToNumber(tierMax1);

      setUserMaxAmountBuy(tierMax1);

      tierMax = calculatePriceOfTokensRoundDown(
        tierMax,
        singlePoolData.tokenDetails.tokenPrice
      );

      tierMax = convertNegetiveNumberToNumber(tierMax1);

      let userPurchased = await web3Instance.checkSales();
      // let data2 = await web3Instance.getTokensAvailableInTier(
      //   projectContract.tier || "0"
      // );
      userPurchased.amount = calculatePriceOfTokensRoundDown(
        userPurchased.amount,
        singlePoolData.tokenDetails.tokenPrice
      );
      // data2 = data2 * parseFloat(singlePoolData.tokenDetails.tokenPrice);
      setMaxAmount(tierMax);

      let allocationLefttokenValue = tierMax - userPurchased.amount;

      // tierMax = tierMax - 10;

      setAllocationLeft(tierMax);

      // setTokensAvailableInTier(data2);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isSocketConnected) {
      socket.on("project-progress", (projectData) => {
        // if (projectData.projectId === singlePoolData._id)
        //   setTierPercentage(projectData.overallPercentage);

        let availableTokens = projectData.availableTokens;

        availableTokens = calculatePriceOfTokensRoundDown(
          availableTokens,
          singlePoolData.tokenDetails.tokenPrice
        );
        setAllocationLeft(availableTokens);
        setMaxAmount(availableTokens);
      });
    }
  }, [isSocketConnected, socket, singlePoolData]);

  const setMaxUserCanInvest = () => {
    setAmount(parseInt(userMaxAmountBuy));
    setUserRecieveValue(
      parseFloat(
        parseFloat(userMaxAmountBuy) /
          parseFloat(singlePoolData.tokenDetails.tokenPrice)
      ).toFixed(2)
    );
  };

  const submitJoinPool = async (e) => {
    e.preventDefault();
    if (isNaN(amount)) {
      toast.info("Please enter a valid number");
      return;
    }

    // if (amount.toString().includes(".")) {
    //   toast.info("Please enter a whole number");
    //   return;
    // }

    if (parseFloat(amount) <= 0) {
      toast.info("Amount cannot be 0 or less than 0");
      return;
    }

    if (parseFloat(amount) > parseFloat(maxAmount)) {
      toast.info("Amount cannot be more than the Allocation left");
      return;
    }

    // else if (parseFloat(amount) > parseFloat(maxAmount)) {
    //   toast.info("Amount cannot be more than the max amount");
    // }

    /*  else if (parseFloat(amount) > userBalance) {
      toast.info("You do not have any tokens to join the pool");
    } */
    else {
      setBuyPoolLoading(true);

      let userEnteredAmount = 0;

      // userEnteredAmount = parseFloat(amount) - 0.01;
      userEnteredAmount = parseFloat(amount);

      try {
        await buyPoolToken(
          userEnteredAmount,
          singlePoolData.projectContractAddress,
          singlePoolData.tokenDetails.tokenPrice
        );
        toast.success("Tokens Successfully Bought");

        if (isSocketConnected) {
          // console.log("first");
          socket.emit("project-progress", {
            projectId: singlePoolData._id,
            tier: currentBuyingTier,
          });
          // console.log("second");
          // socket.emit("all-project-progress");
        }
        // window.location.reload();
      } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong");
      }
      setBuyPoolLoading(false);
      props.toggle_Modal({ show: false });
    }
  };

  return (
    <form onSubmit={submitJoinPool} className="joinpool">
      <div className="avatar">
        <img
          src={singlePoolData.projectLogoUrl}
          className="avatar-img"
          alt={singlePoolData.projectName}
        />
        <NetworkImg 
          networkId={singlePoolData.projectNetwork.networkId}
          networkName={singlePoolData.projectNetwork.networkName}
          className="network"
        />
        {/* <img
          src={singlePoolData.projectNetwork.networkImageUrl}
          alt={singlePoolData.projectName}
          className="network"
        /> */}
      </div>
      <h1 className="heading">{`Join ${projectContract.projectName} FCFS Pool`}</h1>
      <div className="balance text-center">
        {/* {`Wallet Balance: $${userCurrencyBalance}  ${singlePoolData.supportedToken}`}
        <div>{`Max Allocations: $${maxAmount} BUSD`}</div> */}
        <div>
          Allocation Left:{" "}
          <span>{` $${allocationLeft} Left out of $${maxAmount}`}</span>
        </div>
      </div>
      <div className="join-wrap">
        <div className="amount-wrapper">
          {singlePoolData.supportedToken.toLowerCase() === "busd" && (
            <img src={USDCSVG} alt="BUSD" className="token-img" />
          )}
          {singlePoolData.supportedToken.toLowerCase() === "bnb" && (
            <img src={bnbImg} alt="bnb" className="token-img" />
          )}
          {singlePoolData.supportedToken.toLowerCase() === "usdc" && (
            <img src={usdcImg} alt="usdc" className="token-img" />
          )}
          {singlePoolData.supportedToken.toLowerCase() === "usdt" && (
            <img src={usdtImg} alt="usdt" className="token-img" />
          )}
          {singlePoolData.supportedToken.toLowerCase() === "avax" && (
            <img src={avaxImg} alt="avax" className="token-img" />
          )}
          {singlePoolData.supportedToken.toLowerCase() === "matic" && (
            <img src={maticImg} alt="matic" className="token-img" />
          )}
          {singlePoolData.supportedToken.toLowerCase() === "frax" && (
            <img src={fraxImg} alt="frax" className="token-img" />
          )}
          <input
            type="text"
            onChange={(e) => {
              if (e.target.value === "") {
                setUserRecieveValue(0);
                setAmount("");
              } else {
                setAmount(e.target.value);
                setUserRecieveValue(
                  parseFloat(
                    parseFloat(e.target.value) /
                      parseFloat(singlePoolData.tokenDetails.tokenPrice)
                  ).toFixed(2)
                );
              }
            }}
            value={amount}
            className="amount-wrapper-input"
            placeholder="Enter Amount"
          />
          <p className="tokan-curr">
            <button type="button" onClick={setMaxUserCanInvest} className="max">
              MAX
            </button>
          </p>
        </div>
        <div className="max-amount">
          {/* <p>{`Max ${maxAmount} Tokens that can be purchased`}</p>
          <button type="button" onClick={setMaxUserCanInvest} className="max">
            MAX
          </button> */}
          <div className="recive-amount">
            <p>You will Receive</p>
            <p>
              {parseFloat(userRecieveValue).toFixed(2)}{" "}
              {singlePoolData.tokenDetails.tokenSymbol}
            </p>
          </div>
          <div className="recive-amount">
            <p>Conversion Rate</p>
            <p>{singlePoolData.tokenDetails.rate}</p>
          </div>
        </div>
      </div>
      <div className="wallet-balance text-center">
        {`Wallet Balance: $${userCurrencyBalance}  ${singlePoolData.supportedToken}`}
      </div>
      <button
        type="submit"
        className="joinpool-submit"
        disabled={buyPoolLoading}
      >
        {buyPoolLoading ? "Processing..." : "Submit"}
      </button>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    projectContract: state.reducer.projectContract || {},
    singlePoolData: state.reducer.singlePoolData || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_Modal: (show) => dispatch(toggleModal(show)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinPoolFcfs);
