// require('file-loader?name=[name].[ext]!../public/index.html');
import "core-js";
// import 'bootstrap';
import "./assets/fonts/Gilroy-ExtraBold.ttf";
import "./assets/fonts/Gilroy-Bold.ttf";
import "./assets/fonts/Gilroy-SemiBold.ttf";
import "./assets/fonts/Gilroy-Medium.ttf";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const app = (
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer dark autoClose={5000} hideProgressBar={false} />
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById("root"));
