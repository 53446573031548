import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Progress from "react-progressbar";
import Loader from "../../../../components/Loader/Loader";
import { web3Class } from "../../../../Web3/web3Class";

import "../PoolDetails/PoolDetails.css";

const PoolDetailsProgress = ({ tier, singlePoolData }) => {
  const isSocketConnected = useSelector(
    (state) => state.reducer.socket.isConnected
  );
  const socket = useSelector((state) => state.reducer.socket.data);
  const [loading, setLoading] = useState(false);
  const [tierPercentage, setTierPercentage] = useState(0);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        let web3Instance = new web3Class(singlePoolData);
        let percentage = await web3Instance.getSinglePoolPercentage(tier);
        setTierPercentage(percentage);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);

      if (tier === 0) {
        setTierPercentage(0);
      }
    };

    getData();
  }, [tier, singlePoolData]);

  useEffect(() => {
    if (isSocketConnected) {
      socket.on("project-progress", (projectData) => {
        if (
          projectData.projectId === singlePoolData._id &&
          parseFloat(projectData.tier) === parseFloat(tier)
        ) {
          setTierPercentage(projectData.percentage);
        }
      });
    }
  }, [isSocketConnected, socket, tier]);

  if (loading) {
    return <Loader width="50px" />;
  }

  // if (tier === 0 || tier === "0") {
  //   return (
  //     <>
  //       <div className="pool-progress--container">
  //         <Progress color={"#FF2D78"} completed={0} />
  //       </div>
  //       <span className="pool-progress-value">{0} %</span>
  //     </>
  //   );
  // }

  // if (tierPercentage === 0) {
  //   return <>---</>;
  // }

  return (
    <>
      <div className="pool-progress--container">
        <Progress color={"#FF2D78"} completed={tierPercentage} />
      </div>
      <span className="pool-progress-value">{tierPercentage}%</span>
    </>
  );
};

export default PoolDetailsProgress;
