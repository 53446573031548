import React, { useEffect, useState } from "react";
import Loader from "../../../Loader/Loader";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { toggleModal } from "../../../../store/actions/action";
import { checkSales, claimTokens, networkID } from "../../../../Web3/web3";

import "./ClaimFromPool.css";
import { web3Class } from "../../../../Web3/web3Class";

const ClaimFromPool = (props) => {
  const { singlePoolData } = props;
  const [loading, setLoading] = useState(false);
  const [maxAmount, setMaxAmount] = useState("loading...");
  const [tokensAvailableInTier, setTokensAvailableInTier] = useState("loading");

  useEffect(async () => {
    try {
      let saleData = await checkSales();
      setMaxAmount(saleData.amount);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const submitJoinPool = async (e) => {
    e.preventDefault();
    setLoading(true);

    let networkId = await networkID();

    if (
      parseFloat(networkId) !==
      parseFloat(singlePoolData.projectNetwork.networkId)
    ) {
      toast.info(
        `Please connect to ${singlePoolData.projectNetwork.networkName}`
      );
      return;
    }

    try {
      // const contractDetails = {
      //   contractAddress: singlePoolData.projectContractAddress,
      //   networkId: singlePoolData.projectNetwork.networkId,
      //   currencyAbi: singlePoolData.abi.currencyABI,
      //   tokenAbi: singlePoolData.abi.tokenABI,
      //   currencyAddress: singlePoolData.smartContractAddress.currency,
      //   tokenAddress: singlePoolData.smartContractAddress.token,
      // };
      let web3Instance = new web3Class(singlePoolData);
      await web3Instance.claimTokens();
      toast.success("Tokens Successfully Claimed");
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
    setLoading(false);

    props.toggle_Modal({ show: false });
  };

  return (
    <form onSubmit={submitJoinPool} className="joinpool">
      <div className="avatar">
        <img
          src={singlePoolData.projectLogoUrl}
          className="avatar-img"
          alt={singlePoolData.projectName}
        />
        <img
          src={singlePoolData.projectNetwork.networkImageUrl}
          alt={singlePoolData.projectName}
          className="network"
        />
      </div>
      <h1 className="heading">{`Claim From ${singlePoolData.projectName} Pool`}</h1>
      <div className="balance">
        {/* {`Purchased Tokens : ${maxAmount}  ${singlePoolData.supportedToken}`} */}

        <div className="balance__row">
          <div>
            <h1>Token to Claim </h1>
            <p>${singlePoolData.supportedToken}</p>
          </div>

          {/* <button type="submit" className="joinpool-submit" disabled={loading}>
            {loading ? <Loader width="25px" /> : "Claim"}
          </button> */}
        </div>
      </div>

      {/* <p className="info-text">You Can Claim Total amount Only</p> */}

      <p className="info-text">
        Congrajulations! Your alloted tokens will be airdroped by the project
        team
      </p>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    projectContract: state.reducer.projectContract || {},
    singlePoolData: state.reducer.singlePoolData || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_Modal: (show) => dispatch(toggleModal(show)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimFromPool);
