import * as actions from "../actions/actionTypes";

const initialState = {
  network: {},
  connection: {},
  account: {},
  transaction: {},
  notification: {},
  modal: { show: false },
  projectTabs: {
    upcoming: true,
    current: false,
    past: false,
  },
  projectContract: {},
  singlePoolData: {},
  socket: {
    loading: false,
    isConnected: false,
    data: {},
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CONNECT_METAMASK:
      return {
        ...state,
        account: {
          ...state.accounts,
          address: action.payload.data.account.address,
          balance: action.payload.data.account.balance,
          isWhiteListed: action.payload.data.account.isWhiteListed,
        },
        connection: {
          ...state.connection,
          isConnected: action.payload.data.connection.isConnected,
          network: action.payload.data.connection.network,
        },
        notification: {
          ...state.notifications,
          message: action.payload.data.notification.message,
          error: action.payload.data.notification.error,
        },
      };
    case actions.ACCOUNT_DETAILS:
      return {};
    case actions.TRANSACTION_DETAILS:
      return {};
    case actions.NOTIFICATIONS:
      return {};
    case actions.ADDRESS_WHITELISTING:
      return {};
    case actions.DISCONNECT_METAMASK:
      return {};
    case actions.TOGGLE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          show: action.payload.data.show,
          modalType: action.payload.data.modalType,
          modalName: action.payload.data.modalName,
          thankYouType: action.payload.data.thankYouType,
        },
      };
    case actions.PROJECT_TABS:
      return {
        ...state,
        projectTabs: {
          ...state.projectTabs,
          upcoming: action.payload.data.upcoming,
          current: action.payload.data.current,
          past: action.payload.data.past,
        },
      };

    case actions.SET_PROJECT_CONTRACT:
      return {
        ...state,
        projectContract: {
          // address: action.payload.data.contractAddress,
          tier: action.payload.data.tier,
          projectName: action.payload.data.name,
          projectName: action.payload.data.name,
          typeofPool: action.payload.data.typeofPool,
        },
      };

    case actions.SET_SINGLE_POOL_DETAILS:
      return {
        ...state,
        singlePoolData: action.payload.data,
      };

    case actions.INITIALIZE_SOCKET:
      return {
        ...state,
        socket: {
          ...state.socket,
          isConnected: action.payload.data.isConnected,
          data: action.payload.data.data,
        },
      };

    default:
      return state;
  }
};

export default reducer;
