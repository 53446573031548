import React from "react";
import "../../App.css";
import preLoader from "./preLoader.gif";
const PreLoader = ({ width }) => {
  if (width === undefined) {
    width = "25%";
  }

  return (
    <div className="preLoader-spinner">
      <img
        className="preLoader-image"
        src={preLoader}
        alt="preLoader-spinner"
      />
    </div>
  );
};

export default PreLoader;
