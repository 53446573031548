/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import api from "../../../../config/apiCall";
import apiCall from "../../../../config/apiCall";
import { web3Class } from "../../../../Web3/web3Class";
import {
  setProjectContract,
  toggleModal,
} from "../../../../store/actions/action";
import { toast } from "react-toastify";
import { useAccountChange } from "../../../../hooks";
import api from "../../../../config/apiCall";
import { getAddressBelongsToTier, getTierData } from "../../../../Web3/web3";

import "../PoolDetails/PoolDetails.css";
import {
  calculatePriceOfTokens,
  calculatePriceOfTokensRoundUp,
} from "../../../../utils/helpers";
import PoolDetailsProgress from "../PoolDetailsProgress/PoolDetailsProgress";

const UpcomingPoolDetails = (props) => {
  let account = useAccountChange();

  const { singlePoolData } = props;
  const [isJoinWishlistDisabled, setIsJoinWishlistDisabled] = useState(true);
  const [joinWhitelistButtonText, setjoinWhitelistButtonText] =
    useState("Join Whitelist");
  const [tierOfAddress, setTierOfAddress] = useState(0);

  const checkUserIsWhitelisted = async () => {
    let projectId = location.pathname.split("/")[2];
    let walletAddress = account;
    let { data } = await api.getReplace(
      "projects/client/v1/getCurrentDateTime"
    );
    const timestampFromServer = new Date(data.date).toISOString();
    if (timestampFromServer < singlePoolData.idoDates.whitelistStartDate) {
      // setjoinWhitelistButtonText("Join Whitelist");
      return;
    }

    if (timestampFromServer > singlePoolData.idoDates.whitelistEndDate) {
      setIsJoinWishlistDisabled(true);
      let checkUniqueWalletAddress = await apiCall.getReplace(
        `users/checkUserProjectStatus/${projectId}/${walletAddress}`
      );

      if (checkUniqueWalletAddress.data.data === true) {
        // setIsJoinWishlistDisabled(true);
        setjoinWhitelistButtonText("Whitelisted");
      }
      return;
    }

    let checkUniqueWalletAddress = await apiCall.getReplace(
      `users/checkUserProjectStatus/${projectId}/${walletAddress}`
    );

    if (checkUniqueWalletAddress.data.data === true) {
      setIsJoinWishlistDisabled(true);
      setjoinWhitelistButtonText("Whitelisted");
    } else {
      setIsJoinWishlistDisabled(false);
    }

    // country restiction code - ido 57

    let tempCountry = await apiCall.getReplace(
      `kyc/fetchUserCountry/${walletAddress}`
    );

    let userCountry = tempCountry;
    let countryRestrictionArray = singlePoolData.countryRestriction;

    // console.log("singlePoolData", singlePoolData);
    // console.log("singlePoolData.iskyc", singlePoolData.isKYC);
    // console.log(
    //   "Country restriction array",
    //   countryRestrictionArray.includes(userCountry.data.country)
    // );

    if (
      singlePoolData.isKYC === true &&
      countryRestrictionArray.length > 0 &&
      countryRestrictionArray.includes(userCountry.data.country)
    ) {
      setIsJoinWishlistDisabled(true);
      return;
    }
  };

  useEffect(() => {
    async function getTierOfUser() {
      try {
        let tier = await getAddressBelongsToTier(account);
        tier = parseFloat(tier);

        setTierOfAddress(tier);
      } catch (error) {
        console.log(error);
        setTierOfAddress(0);
      }
    }

    if (account !== undefined) {
      checkUserIsWhitelisted();
      if (singlePoolData.isIDOLauched) getTierOfUser();
    }
  }, [account, singlePoolData.isIDOLauched]);

  const PoolSize = ({ tier }) => {
    const [poolSize, setPoolSize] = useState(0);

    useEffect(() => {
      async function getData() {
        let tierData = await getTierData(tier);

        tierData = calculatePriceOfTokensRoundUp(
          tierData,
          singlePoolData.tokenDetails.tokenPrice
        );

        setPoolSize(tierData);
      }

      getData();
    }, []);

    return poolSize;
  };

  const PoolValue = ({ tier }) => {
    const [poolSize, setPoolSize] = useState(0);

    useEffect(() => {
      const getData = async () => {
        let web3Instance = new web3Class(singlePoolData);

        let tierData = await web3Instance.getTierMaxAmountThatCanBeInvested(
          tier
        );

        // console.log(tierData);

        tierData = calculatePriceOfTokensRoundUp(
          tierData,
          singlePoolData.tokenDetails.tokenPrice
        );

        // console.log(tierData, singlePoolData.tokenDetails.tokenPrice);

        setPoolSize(tierData);
      };
      getData();
    }, [tier]);

    if (poolSize === 0) {
      return <>---</>;
    }
    return poolSize;
  };

  const submitWhitelistRequest = async () => {
    if (account !== undefined) {
      let { data } = await api.getReplace(
        "projects/client/v1/getCurrentDateTime"
      );
      const timestampFromServer = new Date(data.date).toISOString();
      try {
        if (timestampFromServer > singlePoolData.idoDates.whitelistEndDate) {
          toast.info("Whitelist time has ended");
          return;
        }

        const { data } = await apiCall.getReplace(
          `kyc/fetchUserStatus/${account}`
        );
        console.log("data", data);

        if (singlePoolData.isKYC && data.status !== "approved") {
          props.toggle_Modal({
            show: true,
            modalType: "thankYou",
            modalName: "Verify Kyc",
            thankYouType: "verifyKyc",
          });
          return;
        }
        let externalArr = singlePoolData.competetionWinner.split(";");
        console.log(externalArr[2]);
        if (
          singlePoolData.competetionWinner === "" ||
          externalArr[0] === undefined ||
          externalArr[1] === undefined ||
          externalArr[2] === undefined
        ) {
          let projectId = location.pathname.split("/")[2];
          await apiCall.postReplace("users/registration", {
            data: {
              projectId: projectId,
              walletAddress: account,
            },
          });

          props.toggle_Modal({
            show: true,
            modalType: "thankYou",
            modalName: "Join Wishlist",
            thankYouType: "WhitelistingRequest",
          });

          checkUserIsWhitelisted();
        } else {
          let projectId = location.pathname.split("/")[2];
          await apiCall.postReplace("users/registration", {
            data: {
              projectId: projectId,
              walletAddress: account,
            },
          });
          props.toggle_Modal({
            show: true,
            modalType: "thankYou",
            modalName: "External User",
            thankYouType: "externalUser",
          });
          checkUserIsWhitelisted();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const submitWhitelistRequestCommunity = async () => {
    if (account !== undefined) {
      let { data } = await api.getReplace(
        "projects/client/v1/getCurrentDateTime"
      );
      const timestampFromServer = new Date(data.date).toISOString();
      try {
        if (timestampFromServer > singlePoolData.idoDates.whitelistEndDate) {
          toast.info("Whitelist time has ended");
          return;
        }

        const { data } = await apiCall.getReplace(
          `kyc/fetchUserStatus/${account}`
        );
        console.log("data", data);

        if (singlePoolData.isKYC && data.status !== "approved") {
          props.toggle_Modal({
            show: true,
            modalType: "thankYou",
            modalName: "Verify Kyc",
            thankYouType: "verifyKyc",
          });
          return;
        }
        props.toggle_Modal({
          show: true,
          modalType: "thankYou",
          modalName: "Join Competition",
          thankYouType: "gleam",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (singlePoolData.isIDOLauched === false) {
    if (singlePoolData.typeOfProject === "Community IDO") {
      return (
        <div className="pool__grid">
          <div className="pool-tier">Community Pool</div>
          <div className="pool-size">
            {/* <PoolSize tier="4" /> */}
            ---
          </div>
          <div className="pool-value">
            {/* <PoolValue tier="4" /> */}
            ---
          </div>
          <div className="pool-progress">
            {/* <PoolDetailsProgress tier={0} /> */}
            ---
          </div>
          <div className="pool-action">
            <button
              className="active"
              onClick={submitWhitelistRequestCommunity}
              disabled={isJoinWishlistDisabled}
            >
              Join competition
            </button>
          </div>
        </div>
      );
    } else if (
      singlePoolData.typeOfProject === "Public IDO" ||
      singlePoolData.typeOfProject === "Private IDO"
    ) {
      return (
        <>
          <div className="pool__grid">
            <div className="pool-tier">Tier</div>
            <div className="pool-size">TBA</div>
            <div className="pool-value">TBA</div>
            <div className="pool-progress">TBA</div>
            <div className="pool-action">
              <button
                className="active"
                disabled={isJoinWishlistDisabled}
                onClick={submitWhitelistRequest}
              >
                {joinWhitelistButtonText}
              </button>
            </div>
          </div>
        </>
      );
    }
  } else if (singlePoolData.isIDOLauched === true) {
    if (singlePoolData.typeOfProject === "Community IDO") {
      return (
        <div
          className={
            tierOfAddress === 4 ? "pool__grid whitelistedUser" : "pool__grid"
          }
        >
          <div className="pool-tier">Community Pool</div>
          <div className="pool-size">
            $<PoolSize tier="4" />
          </div>
          <div className="pool-value">
            $<PoolValue tier="4" />
          </div>
          <div className="pool-progress">
            <PoolDetailsProgress tier={4} singlePoolData={singlePoolData} />
          </div>
          <div className="pool-action">
            <button className="active" disabled={true}>
              Join Pool
            </button>
          </div>
          <div class="custom-tooltip">Allocated Tier</div>
        </div>
      );
    } else if (
      singlePoolData.typeOfProject === "Public IDO" ||
      singlePoolData.typeOfProject === "Private IDO"
    ) {
      return (
        <>
          <div
            className={
              tierOfAddress === 1 ? "pool__grid whitelistedUser" : "pool__grid"
            }
          >
            <div className="pool-tier">Moon</div>
            <div className="pool-size">
              $<PoolSize tier="1" />
            </div>
            <div className="pool-value">
              $<PoolValue tier="1" />
            </div>
            <div className="pool-progress">
              <PoolDetailsProgress tier={1} singlePoolData={singlePoolData} />
            </div>
            <div className="pool-action">
              <button className="active" disabled={true}>
                Join Pool
              </button>
            </div>
            <div class="custom-tooltip">Allocated Tier</div>
          </div>
          <div
            className={
              tierOfAddress === 2 ? "pool__grid whitelistedUser" : "pool__grid"
            }
          >
            <div className="pool-tier">Sun</div>
            <div className="pool-size">
              $<PoolSize tier="2" />
            </div>
            <div className="pool-value">
              $<PoolValue tier="2" />
            </div>
            <div className="pool-progress">
              <PoolDetailsProgress tier={2} singlePoolData={singlePoolData} />
            </div>
            <div className="pool-action">
              <button className="active" disabled={true}>
                Join Pool
              </button>
            </div>
            <div class="custom-tooltip">Allocated Tier</div>
          </div>
          <div
            className={
              tierOfAddress === 3 ? "pool__grid whitelistedUser" : "pool__grid"
            }
          >
            <div className="pool-tier">Galaxy</div>
            <div className="pool-size">
              $<PoolSize tier="3" />
            </div>
            <div className="pool-value">
              $<PoolValue tier="3" />
            </div>
            <div className="pool-progress">
              <PoolDetailsProgress tier={3} singlePoolData={singlePoolData} />
            </div>
            <div className="pool-action">
              <button className="active" disabled={true}>
                Join Pool
              </button>
            </div>
            <div class="custom-tooltip">Allocated Tier</div>
          </div>
        </>
      );
    }
  }
  return <></>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_Modal: (show) => dispatch(toggleModal(show)),
    setProjectContractDetails: (data) => dispatch(setProjectContract(data)),
  };
};

export default connect(null, mapDispatchToProps)(UpcomingPoolDetails);
