import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { toggleModal } from "../../store/actions/action";
// import { ProjectForm } from "./Forms";
import UserForm from "./Forms/UserForm";
import ApplyIdoForm from "./Forms/ApplyIdoForm";
import JoinWishlist from "./Modal/JoinWishlist/JoinWishlist";
import JoinPool from "./Modal/JoinPool/JoinPool";
import ClaimFromPool from "./Modal/ClaimFromPool/ClaimFromPool";
import JoinPoolFcfs from "./Modal/JoinPool/JoinPoolFcfs";
import ConnectWallet from "./Modal/ConnectWallet/ConnectWallet";
import ThankYou from "./Modal/ThankYou/ThankYou";
// import JoinPool from "./UI/Custom-Modals";
import "./Modal.css";
const ModalBody = ({ modalType }) => {
  if (modalType === "project") {
    return <ApplyIdoForm />;
  }

  if (modalType === "user") {
    return <UserForm />;
  }

  if (modalType === "joinPool") {
    return <JoinPool />;
  }

  if (modalType === "joinFcfsPool") {
    return <JoinPoolFcfs />;
  }

  if (modalType === "joinWishlist") {
    return <JoinWishlist />;
  }

  if (modalType === "claimFromPool") {
    return <ClaimFromPool />;
  }

  if (modalType === "connectWallet") {
    return <ConnectWallet />;
  }

  if (modalType === "thankYou") {
    return <ThankYou />;
  }

  return <></>;
};

const CustomModal = (props) => {
  return (
    <Modal
      show={props.modal.show}
      onHide={() => props.toggle_Modal({ show: false })}
      backdrop="static"
      keyboard={false}
      animation={true}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {/* {props.modal.modalType === "projects" ||
          props.modal.modalType === "user"
            ? "Form: "
            : "Join "} */}
          {/* {props.modalDetails.name} */}
          {/* {props.modal.modalType === "joinPool" ? " Pool" : null} */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {props.modal.modalType === "project" ? (
          <ApplyIdoForm />
        ) : props.modal.modalType === "user" ? (
          <UserForm
            address={props.account.address}
            projectId={props.modal.projectId}
          />
        ) : props.modal.modalType === "joinPool" ? (
          <JoinPool />
        ) : null} */}

        <ModalBody modalType={props.modal.modalType} />
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    modal: state.reducer.modal || {},
    account: state.reducer.account || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_Modal: (show) => dispatch(toggleModal(show)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomModal);
