import React from "react";
import copy from "copy-to-clipboard";

import CopyIcon from "./assets/CopyIcon.png";
import { toast } from "react-toastify";

const TokenDetailsTab = ({ singlePoolData }) => {
  const copyIconStyles = {
    width: "20px",
    height: "20px",
    display: "inline-block",
    marginLeft: "10px",
  };

  const copyContractAddress = (tokenContractAddress) => {
    copy(tokenContractAddress);
  };

  return (
    <div className="tokenDetails">
      <div className="tokenDetails__wrapper">
        <p className="heading">Token Name</p>
        <p className="value">{singlePoolData.tokenDetails.tokenName}</p>
        <p className="heading">Symbol</p>
        <p className="value">{singlePoolData.tokenDetails.tokenSymbol}</p>
        {/* <p className="heading">Contract</p>
        <p className="value">
          {singlePoolData.tokenDetails.tokenContract?.tokenContractLink}{" "}
          <img
            src={CopyIcon}
            className="copyIcon"
            alt="CopyIcon"
            onClick={() => {
              copyContractAddress(
                singlePoolData.tokenDetails.tokenContract?.tokenContractLink
              );
              toast.info("Copied to Clipboard");
            }}
          />
        </p> */}
        {/* <p className="heading">Blockchain</p>
        <p className="value">{singlePoolData.projectNetwork.networkName}</p> */}
        <p className="heading">Token Price </p>
        <p className="value">${singlePoolData.tokenDetails.tokenPrice}</p>
        <p className="heading">Vesting</p>
        <p className="value">{singlePoolData.tokenDetails.vesting}</p>
      </div>
    </div>
  );
};

export default TokenDetailsTab;
