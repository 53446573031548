import { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Layout from "./hoc/Layout/layout";
import SinglePool from "./pages/SinglePool/SinglePool";
import "./App.css";
import PreLoader from "./components/Loader/PreLoader";
const Pools = lazy(() => import("./pages/Pools/Pools"));
const HomePage = lazy(() => import("./components/homepage/homepage"));
// const ProjectPage = lazy(() => import("./components/Projects/projects"));
// const ProjectDescription = lazy(() =>
//   import("./components/ProjectDescription/project-description")
// );

const App = () => {
  return (
    <Router>
      <Suspense fallback={<PreLoader />}>
        <Layout>
          <Switch>
            <Route path="/" exact component={Pools}>
              <Redirect to="/projects"></Redirect>
            </Route>
            <Route path="/home" exact component={HomePage}></Route>
            <Route path="/projects" exact component={Pools}></Route>
            <Route path="/project-detail/:id" component={SinglePool}></Route>
          </Switch>
        </Layout>
      </Suspense>
    </Router>
  );
};

export default App;
