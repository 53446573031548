import React from "react";
import { connect } from "react-redux";
import { toggleModal } from "../../../../store/actions/action";

import JoinPool from "./assets/JoinPool.png";
import KycRequest from "./assets/KycRequest.png";
import WhitelistingRequest from "./assets/WhitelistingRequest.png";
import VerifyKyc from "./assets/VerifyKyc.png";
import Gleam from "./assets/gleam.png";
import "./ThankYou.scss";

const ThankYou = ({ modal, toggle_Modal, singlePoolData }) => {
  const onClose = () => {
    toggle_Modal({ show: false });
  };

  if (modal.thankYouType === "joinPool") {
    return (
      <div className="thankyou">
        <img className="thankyou__img" src={JoinPool} alt="JoinPool" />

        <p className="thankyou__text">Thanks for joining the pool.</p>
        <button className="thankyou__btn" onClick={onClose}>
          Close
        </button>
      </div>
    );
  }

  if (modal.thankYouType === "verifyKyc") {
    return (
      <div className="thankyou">
        <img className="thankyou__img" src={VerifyKyc} alt="VerifyKyc" />

        <p className="thankyou__text">
          Verify your KYC to apply for the whitelist.
        </p>
        <button
          className="thankyou__btn"
          onClick={() =>
            window.open(
              "https://verify-with.blockpass.org/?clientId=asva_ido_on_metalaunch_cce82&serviceName=Asva%20IDO%20on%20MetaLaunch&env=prod"
            )
          }
        >
          Verify KYC
        </button>
      </div>
    );
  }
  if (modal.thankYouType === "gleam") {
    return (
      <div className="thankyou">
        <img className="thankyou__img" src={Gleam} alt="Gleam" />

        <p className="thankyou__text">
          Join competition to apply for whitelist
        </p>
        <button
          className="joincompitation"
          onClick={() => window.open(`${singlePoolData.competetionWinner}`)}
        >
          Join Now
        </button>
      </div>
    );
  }
  if (modal.thankYouType === "externalUser") {
    let externalArr = singlePoolData.competetionWinner.split(";");
    console.log(externalArr);
    return (
      <div className="thankyou">
        <img className="thankyou__img" src={WhitelistingRequest} alt="Gleam" />

        <p className="thankyou__text">{externalArr[0]}</p>
        <button
          className="joincompitation"
          onClick={() => window.open(`${externalArr[2]}`)}
        >
          {externalArr[1]}
        </button>
      </div>
    );
  }
  if (modal.thankYouType === "KycRequest") {
    return (
      <div className="thankyou">
        <img className="thankyou__img" src={KycRequest} alt="KycRequest" />

        <p className="thankyou__text">Thanks for your request for KYC.</p>
        <button className="thankyou__btn" onClick={onClose}>
          Close
        </button>
      </div>
    );
  }

  if (modal.thankYouType === "WhitelistingRequest") {
    return (
      <div className="thankyou">
        <img
          className="thankyou__img"
          src={WhitelistingRequest}
          alt="WhitelistingRequest"
        />

        <p className="thankyou__text">
          Thanks for your request for whitelisting.
        </p>
        <button className="thankyou__btn" onClick={onClose}>
          Close
        </button>
      </div>
    );
  }

  return (
    <div className="thankyou">
      <img className="thankyou__img" src={JoinPool} alt="JoinPool" />

      <p className="thankyou__text">Thanks for your request for KYC.</p>
      <button className="thankyou__btn" onClick={onClose}>
        Close
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modal: state.reducer.modal || {},
    singlePoolData: state.reducer.singlePoolData || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle_Modal: (show) => dispatch(toggleModal(show)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
