import Web3 from "web3";
import moment from "moment";

// import ABI from "./ABI.json";
// import NEWABI from "./NEW_ABI.json";
import NEWABI from "./NEW_ABI.json";
import MAINNETABI from "./TierIDOPool.json";
import CLAIMABI from "./CLAIM_ABI.json";
import { toast } from "react-toastify";
import { currentEnvironment } from "../config/environment";
import { fetchAccountDetails } from "./web3";
import { web3Configuration } from "./config";
import { convertToEther } from "../helpers/convertDecimals";

// const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
// const web3 = new Web3(
//   "https://speedy-nodes-nyc.moralis.io/31649378acd900255d51e632/polygon/mainnet" ||
//     Web3.givenProvider
// );

// let ABI = [];

// let web3;

// if (currentEnvironment === "development") {
//   ABI = NEWABI;
//   web3 = new Web3(
//     "https://matic.getblock.io/testnet/?api_key=11e35fe6-558a-4850-b56c-7e6cf1ea27ff" ||
//       Web3.givenProvider
//   );
// } else if (currentEnvironment === "production") {
//   ABI = MAINNETABI;
//   web3 = new Web3(
//     "https://speedy-nodes-nyc.moralis.io/31649378acd900255d51e632/bsc/mainnet" ||
//       Web3.givenProvider
//   );
// }

let web3 = "";

export class web3Class {
  constructor(singlePoolData) {
    // const {
    //   contractAddress,
    //   networkId,
    //   currencyAbi,
    //   tokenAbi,
    //   currencyAddress,
    //   tokenAddress,
    // } = contractDetails;

    // console.log(singlePoolData.tokenDetails.decimals, singlePoolData.currDecimal);

    this.tokenInfo = {
      decimals: parseFloat(singlePoolData.currDecimal),
    };

    let contractDetails = {
      tokenAbi: singlePoolData.abi.tokenABI,
      currencyAbi: singlePoolData.abi.currencyABI,
      tokenAddress: singlePoolData.smartContractAddress.token,
      contractAddress: singlePoolData.projectContractAddress,
      currencyAddress: singlePoolData.smartContractAddress.currency,
      networkId: singlePoolData.projectNetwork.networkId,
      decimals: singlePoolData.tokenDetails.decimals,
    };

    this.contractAddress = contractDetails.contractAddress;
    this.currencyAbi =
      contractDetails.currencyAbi.length > 0
        ? JSON.parse(contractDetails.currencyAbi)
        : contractDetails.currencyAbi;
    // this.tokenAbi =
    //   contractDetails.tokenAbi.length > 0
    //     ? JSON.parse(contractDetails.tokenAbi)
    //     : contractDetails.tokenAbi;
    this.currencyAddress = contractDetails.currencyAddress;
    this.tokenAddress = contractDetails.tokenAddress;

    web3 = new Web3(
      web3Configuration[contractDetails.networkId].rpc || Web3.givenProvider
    );
    this.contractInstance = new web3.eth.Contract(
      NEWABI,
      contractDetails.contractAddress
    );
    this.currencyInstance = new web3.eth.Contract(
      this.currencyAbi,
      contractDetails.currencyAddress
    );

    // this.tokenInfo = {
    //   symbol: "",
    //   decimals: contractDetails.decimals,
    // };

    // console.log(this.tokenInfo);

    // this.getTokenInfo();

    // this.showAddress();
  }

  async getData() {
    let d = await this.getNetworkId();
    return d;
  }

  showAddress() {
    console.log("----show----");
    console.log(this.tokenInfo);
    console.log("----show----");
    // console.log(this.contractAddress);
    // console.log(this.contractInstance);
  }

  getNetworkId() {
    return new Promise(async (resolve, reject) => {
      try {
        let id = await web3.eth.net.getId();
        resolve(id);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  getTokenInfo() {
    return new Promise(async (resolve, reject) => {
      try {
        const symbol = await this.currencyInstance.methods.symbol().call();
        const decimals = await this.currencyInstance.methods.decimals().call();

        this.tokenInfo = {
          symbol: symbol,
          decimals: parseFloat(decimals),
        };
        resolve(true);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  getTotalAmount() {
    return new Promise(async (resolve, reject) => {
      try {
        const contractData = await this.contractInstance.methods
          .totalAmount()
          .call();
        resolve(await convertToEther(contractData, this.tokenInfo.decimals));
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  getTotalAmountSold() {
    return new Promise(async (resolve, reject) => {
      try {
        const contractData = await this.contractInstance.methods
          .totalAmountSold()
          .call();
        resolve(await convertToEther(contractData, this.tokenInfo.decimals));
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  calculateOverallPoolPercentage = () => {
    return new Promise(async (resolve) => {
      let totalAmount = await this.getTotalAmount();
      let totalAmountSold = await this.getTotalAmountSold();

      let percentage = (totalAmountSold / totalAmount) * 100;

      // percentage = parseFloat(percentage).toFixed(2);

      percentage = parseFloat(percentage);
      percentage = Math.ceil(percentage);

      resolve(percentage);
    });
  };

  getSinglePoolPercentage = (tier) => {
    return new Promise(async (resolve, reject) => {
      try {
        let tierData = await this.contractInstance.methods
          .tierAllocations(tier)
          .call();

        tierData = await convertToEther(tierData, this.tokenInfo.decimals);

        let tokensAvailableInTier = await this.contractInstance.methods
          .tokensAvailableInTier(tier)
          .call();
        tokensAvailableInTier = await convertToEther(
          tokensAvailableInTier,
          this.tokenInfo.decimals
        );

        let progress = tierData - tokensAvailableInTier;
        let percentage = (progress / tierData) * 100;

        // percentage = parseFloat(percentage).toFixed(2);
        percentage = parseFloat(percentage);
        percentage = Math.ceil(percentage);

        resolve(percentage);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAllRoundsTime = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let round1Start = await this.contractInstance.methods
          .round1Start()
          .call();
        let round1End = await this.contractInstance.methods.round1End().call();
        let round2Start = await this.contractInstance.methods
          .round2Start()
          .call();
        let round2End = await this.contractInstance.methods.round2End().call();

        let data = {
          round1Start: round1Start,
          round1End: round1End,
          round2Start: round2Start,
          round2End: round2End,
        };
        resolve(data);
      } catch (error) {
        reject(error);
        // toast.error("Some error occurred while fetching the time of the pools");
      }
    });
  };

  isPoolActive = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let round1Start = await this.contractInstance.methods
          .round1Start()
          .call();
        let round1End = await this.contractInstance.methods.round1End().call();
        let round2Start = await this.contractInstance.methods
          .round2Start()
          .call();
        let round2End = await this.contractInstance.methods.round2End().call();
        let currentTime = moment().unix();

        if (currentTime > round1Start && currentTime < round1End) {
          resolve({
            current: "round1",
            status: true,
          });
        }

        if (currentTime > round1End && currentTime < round2Start) {
          resolve({
            current: "fcfs",
            status: "upcoming",
          });
        }

        if (currentTime > round2Start && currentTime < round2End) {
          resolve({
            current: "fcfs",
            status: true,
          });
        }

        if (currentTime > round2End) {
          resolve({
            current: "fcfs",
            status: false,
          });
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  getNumberParticipants = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let data = await this.contractInstance.methods
          .numberParticipants()
          .call();

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getTierData = (tier) => {
    return new Promise(async (resolve, reject) => {
      try {
        const contractData = await this.contractInstance.methods
          .tierAllocations(tier)
          .call();

        let data = await convertToEther(contractData, this.tokenInfo.decimals);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAddressBelongsToTier = (address) => {
    return new Promise(async (resolve, reject) => {
      try {
        let data = await this.contractInstance.methods
          .addressBelongsToTier(address)
          .call();
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  calculateTotalAvailableTokensInAllTiers = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let data = await this.contractInstance.methods.availableTokens().call();
        data = await convertToEther(data, this.tokenInfo.decimals);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  checkSales = () => {
    return new Promise(async (resolve, reject) => {
      const { account } = await fetchAccountDetails();

      let buyAddress = account.address;

      // buyAddress = "0x6436069d93373Ab48b5Ff65FB06f95Fd54410925";

      try {
        const contractData = await this.contractInstance.methods
          .sales(buyAddress)
          .call();
        const { amount, tokensWithdrawn, investor } = contractData;
        // console.log(contractData);

        // if (amount === "0") {
        //   toast.info("There are no purchased tokens");
        // }

        resolve({
          amount: parseFloat(
            await convertToEther(amount, this.tokenInfo.decimals)
          ),
          tokensWithdrawn,
          investor,
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  claimTokens = () => {
    return new Promise(async (resolve, reject) => {
      const { account } = await fetchAccountDetails();
      const buyAddress = account.address;

      try {
        let claimContractInstance = await new web3.eth.Contract(
          CLAIMABI,
          this.contractAddress
        );

        const contractData = await claimContractInstance.methods
          .claimTokens()
          .send({
            from: buyAddress,
          });
        // console.log(contractData);
        resolve(contractData);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  getTierMaxAmountThatCanBeInvested = (tier) => {
    return new Promise(async (resolve) => {
      const contractData = await this.contractInstance.methods
        .tierMaxAmountThatCanBeInvested(tier)
        .call();

      resolve(await convertToEther(contractData, this.tokenInfo.decimals));
    });
  };

  getTokensAvailableInTier = (tier) => {
    return new Promise(async (resolve) => {
      const contractData = await this.contractInstance.methods
        .tokensAvailableInTier(tier)
        .call();

      resolve(await convertToEther(contractData, this.tokenInfo.decimals));
    });
  };
}
