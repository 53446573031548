import React from 'react'
import "../PoolCard/PoolCard.css"
import "../../pages/SinglePool/components/Sidebar/Sidebar.css"
import "../../components/shared/Modal/JoinPool/JoinPool.scss"
import bscNetworkImg from "../../assets/images/BSC 32 x 32-29.png"
import polygonNetworkImg from "../../assets/images/polygon 32x32-29.png"
import avaxNetworkImg from "../../assets/images/avax 32x32-29.png"
import terraNetworkImg from "../../assets/images/terra 32x32-29.png"
import solanaNetworkImg from "../../assets/images/solana 32x32-29.png"

function NetworkImg({networkId, networkName, className}) {
    let renderImg = ""
    if(networkId === 97 || networkId === 56){
      renderImg = bscNetworkImg
    }else if(networkId === 137 || networkId === 80001){
      renderImg = polygonNetworkImg
    }else if(networkId === 43114 || networkId === 43113){
      renderImg = avaxNetworkImg
    }
    return (
      <img
        src={renderImg}
        className={className}
        alt={networkName}
      />
    )
}

export default NetworkImg