import React from "react";
import logo from "../../assets/images/metalaunch.png";

const MetaFooter = () => {
  return (
    <footer className="footer__new">
      <div className="footer__new-div">
        <img src={logo} alt="logo" />
        <p>Multi-chain metaverse launchpad</p>
      </div>
    </footer>
  );
};

export default MetaFooter;
