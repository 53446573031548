import React, { useEffect, useState } from "react";
import Progress from "react-progressbar";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import { calculateOverallPoolPercentage } from "../../../../utils/PoolInteraction";
import { getTierData, getTokensAvailableInTier } from "../../../../Web3/web3";
import { web3Class } from "../../../../Web3/web3Class";

import "../PoolDetails/PoolDetails.css";

const PoolDetailsProgressFcfs = ({ singlePoolData }) => {
  const [loading, setLoading] = useState(false);
  const [tierPercentage, setTierPercentage] = useState(0);
  const isSocketConnected = useSelector(
    (state) => state.reducer.socket.isConnected
  );
  const socket = useSelector((state) => state.reducer.socket.data);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let web3Instance = new web3Class(singlePoolData);
      let percentage = await web3Instance.calculateOverallPoolPercentage();
      setTierPercentage(percentage);
      setLoading(false);
    };

    getData().catch(console.error);
  }, [singlePoolData]);

  useEffect(() => {
    if (isSocketConnected) {
      socket.on("project-progress", (projectData) => {
        if (projectData.projectId === singlePoolData._id)
          setTierPercentage(projectData.overallPercentage);
      });
    }
  }, [isSocketConnected, singlePoolData, socket]);

  if (loading) {
    return <Loader width="50px" />;
  }

  return (
    <>
      <div className="pool-progress--container">
        <Progress color={"#FF2D78"} completed={tierPercentage} />
      </div>
      <span className="pool-progress-value">{tierPercentage}%</span>
    </>
  );
};

export default PoolDetailsProgressFcfs;
