export const getBackendUrl = (env_var) => {
    // console.log("env_var ", env_var);
    if (env_var.toUpperCase().trim() === "LOCAL") {
      return "http://dev-admin-api.metalaunch.io/";
    } else if (env_var.toUpperCase().trim() === "DEV") {
      return "http://dev-admin-api.metalaunch.io/";
    } else if (env_var.toUpperCase().trim() === "QA") {
      return "http://qa-admin-api.metalaunch.io/";
    } else if (env_var.toUpperCase().trim() === "PROD") {
      return "https://api.metalaunch.io/";
    }
  };