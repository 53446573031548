import moment from "moment";

import { toast } from "react-toastify";
import BigNumber from "bignumber.js";
import Web3 from "web3";
import NEWABI from "./NEW_ABI.json";
import MAINNETABI from "./TierIDOPool.json";
import CURRENCYABI from "./CURRENCY_ABI.json";
import DEVCURRENCYABI from "./DEV_CURRENCY_ABI.json";
import USDCCURRENCYABI from "./USDC_CURRENCY.json";
import test from "./test.json";
import { currentEnvironment } from "../config/environment";
import { web3Configuration } from "./config";
import { convertToDecimal, convertToEther } from "../helpers/convertDecimals";
import { roundOffBuyValue } from "../utils/helpers";
import api from "../config/apiCall";
const { ethereum } = window;

// const web3 = new Web3("https://bsc.getblock.io/testnet/?api_key=c5f088a2-ed4a-4f31-ab36-e6f47cecc8b6");
// const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
// const web3 = new Web3(
//   Web3.givenProvider ||
//     "https://speedy-nodes-nyc.moralis.io/31649378acd900255d51e632/polygon/mumbai"
// );

let contractAbi = [];
let currencyAbi = [];
let tokenAbi = [];
let contractAddress = "";
let contractInstance = "";
let currencyAddress = "";
let currencyInstance = "";
let tokenAddress = "";
let web3;
let tokenInfo = {
  decimals: 0,
  symbol: "",
};

web3 = new Web3(Web3.givenProvider);

// if (currentEnvironment === "development") {
//   ABI = NEWABI;
//   web3 = new Web3(
//     // "https://matic.getblock.io/mainnet/?api_key=ac663d41-8448-4308-a84f-5df24b84d6ec" ||
//     Web3.givenProvider
//   );
// } else if (currentEnvironment === "production") {
//   ABI = MAINNETABI;
//   web3 = new Web3(
//     Web3.givenProvider ||
//       "https://speedy-nodes-nyc.moralis.io/31649378acd900255d51e632/bsc/mainnet"
//   );
// }

export const web3Initialise = (singlePoolData) => {
  return new Promise(async (resolve, reject) => {
    let contractDetails = {
      tokenAbi: singlePoolData.abi.tokenABI,
      currencyAbi: singlePoolData.abi.currencyABI,
      contractAddress: singlePoolData.projectContractAddress,
      tokenAddress: singlePoolData.smartContractAddress.token,
      currencyAddress: singlePoolData.smartContractAddress.currency,
      networkId: singlePoolData.projectNetwork.networkId,
      decimals: singlePoolData.tokenDetails.decimals,
    };

    // console.log(contractDetails);

    web3 = await new Web3(
      Web3.givenProvider || web3Configuration[contractDetails.networkId].rpc
    );

    contractAbi = NEWABI;
    contractAddress = contractDetails.contractAddress;
    currencyAddress = contractDetails.currencyAddress;
    currencyAddress = contractDetails.currencyAddress;
    // tokenAbi =
    //   contractDetails.tokenAbi.length > 0
    //     ? JSON.parse(contractDetails.tokenAbi)
    //     : [];
    currencyAbi =
      contractDetails.currencyAbi.length > 0
        ? JSON.parse(contractDetails.currencyAbi)
        : [];

    contractInstance = await new web3.eth.Contract(
      contractAbi,
      contractAddress
    );

    currencyInstance = await new web3.eth.Contract(
      currencyAbi,
      currencyAddress
    );

    tokenInfo = {
      symbol: "symbol",
      decimals: contractDetails.decimals,
    };

    // await getTokenInfo();

    // let fetchedTokenInfo = getTokenInfo();

    // tokenInfo = fetchedTokenInfo;

    // console(tokenInfo);

    resolve(true);
  });
};

// web3Initialise()

const getTokenInfo = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const symbol = await currencyInstance.methods.symbol().call();
      const decimals = await currencyInstance.methods.decimals().call();

      tokenInfo = {
        symbol: symbol,
        decimals: parseFloat(decimals),
      };
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

function convertDecimalToHexChainID(number) {
  // convertDecimalToHex
  if (typeof number !== "number") number = parseInt(number);

  let hex = number.toString(16);
  // returning the chainID
  return "0x" + hex;
}

export const initiateConnection = async () => {
  if (ethereum && ethereum.isMetaMask) {
    return await checkConnectionNetwork();
  }
  return false;
};

export const checkMetaMaskInstalled = () => {
  if (ethereum && ethereum.isMetaMask) {
    return true;
  }
  return false;
};

export const checkConnectionNetwork = () => {
  return new Promise(async (resolve, reject) => {
    resolve(fetchAccountDetails());
  });
};

export const checkLoggedIn = () => {
  return new Promise(async (resolve, reject) => {
    let id = await web3.eth.net.getId();
    resolve(id);
  });
};

export const checkValidContractAddress = (address) => {
  return new Promise(async (resolve, reject) => {
    // resolve(fetchAccountDetails());
    try {
      resolve(await web3.utils.isAddress(address));
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export function networkID() {
  return new Promise(async (resolve, reject) => {
    try {
      let id = await web3.eth.net.getId();
      resolve(id);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}

export function getChainId() {
  return new Promise(async (resolve, reject) => {
    try {
      let id = await web3.eth.getChainId();
      resolve(id);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}

export const createCurrencyContractInstance = () => {
  // Replace new abi and address with the live one
  return new Promise(async (resolve, reject) => {
    try {
      let contractInstance1 = "";

      // if (currentEnvironment === "production") {
      //   contractInstance1 = await new web3.eth.Contract(
      //     USDCCURRENCYABI,
      //     "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      //   );
      // } else if (currentEnvironment === "development") {
      //   contractInstance1 = await new web3.eth.Contract(
      //     DEVCURRENCYABI,
      //     "0x6e417ED32794D24a2e188d734B5b0747cD24DFE8"
      //   );
      // }

      contractInstance1 = await new web3.eth.Contract(
        currencyAbi,
        currencyAddress
      );

      resolve(contractInstance1);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getCurrencyBalance = () => {
  // Replace new abi and address with the live one
  return new Promise(async (resolve, reject) => {
    try {
      const account = await web3.eth.requestAccounts();
      let contractInstance1 = "";

      // if (currentEnvironment === "production") {
      //   contractInstance1 = await new web3.eth.Contract(
      //     USDCCURRENCYABI,
      //     "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
      //   );
      // } else if (currentEnvironment === "development") {
      //   contractInstance1 = await new web3.eth.Contract(
      //     DEVCURRENCYABI,
      //     "0x6e417ED32794D24a2e188d734B5b0747cD24DFE8"
      //   );
      // }

      // contractInstance1 = await new web3.eth.Contract(
      //   currencyAbi,
      //   currencyAddress
      // );

      contractInstance1 = await new web3.eth.Contract(
        currencyAbi,
        currencyAddress
      );

      // console.log(contractInstance1);

      let balance = await contractInstance1.methods
        .balanceOf(account[0])
        .call();

      // balance = new BigNumber(balance).divideBy(Math.pow(10, 18));

      // console.log(data);

      // let balanceBn = await web3.utils.toBN(balance);

      // console.log()

      balance = await convertToEther(balance, tokenInfo.decimals);

      // console.log(balanceEther);

      // balance = web3.utils.hexToNumber(balance) / Math.pow(10, 6);

      resolve(balance);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const fetchAccountDetails = () => {
  return new Promise(async (resolve, reject) => {
    const account = await web3.eth.requestAccounts();
    if (account.length < 1) {
      const notificaton = {
        message: "No Account Found",
        error: true,
      };
      toast.error("Account not Connected");
      reject(notificaton);
    } else {
      const details = {
        account: {
          address: account[0],
          balance: await web3.eth.getBalance(account[0]),
          isWhiteListed: false,
        },
        connection: {
          isConnected: true,
          network: await web3.eth.net.getNetworkType(),
          networkId: await web3.eth.net.getId(),
        },
        notification: {
          message: `BSC Testnet Network Connected`,
          error: false,
        },
      };
      resolve(details);
    }
  });
};

export const getAccountBalance = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const account = await web3.eth.requestAccounts();
      let balance = await web3.eth.getBalance(account[0]);

      // resolve(convertToEther(balance, tokenInfo.decimals));
      resolve(web3.utils.fromWei(balance, "ether"));
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const switchNetwork = async (network) => {
  network = convertDecimalToHexChainID(network);
  return new Promise((resolve, reject) => {
    window.ethereum
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: network }],
      })
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        reject(false);
      });
  });

  // console.log(status);
};

export function getTotalAmount() {
  return new Promise(async (resolve, reject) => {
    try {
      const contractData = await contractInstance.methods.totalAmount().call();
      resolve(convertToEther(contractData, tokenInfo.decimals));
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}

export function getTotalAmountSold() {
  return new Promise(async (resolve, reject) => {
    try {
      const contractData = await contractInstance.methods
        .totalAmountSold()
        .call();
      resolve(convertToEther(contractData, tokenInfo.decimals));
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}

export const getUserBalanceForBuying = () => {
  // Get the balance of Curr Token to be replaced with BUSDT
  return new Promise(async (resolve, reject) => {
    let currencyContractInstance = await createCurrencyContractInstance();
    try {
      const { account } = await fetchAccountDetails();
      const buyAddress = account.address;
      const contractData = await currencyContractInstance.methods
        .balanceOf(buyAddress)
        .call();
      let balance = await convertToEther(contractData, tokenInfo.decimals);
      resolve(balance);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

// web3.eth.getBalance("0x407d73d8a49eeb85d32cf465507dd71d507100c1")
// .then(console.log);

export const createContractInstance = async (projectContractAddress) => {
  contractInstance = await new web3.eth.Contract(
    contractAbi,
    projectContractAddress
  );
};

export const createContractInstanceObject = async (projectContractAddress) => {
  contractInstance = await new web3.eth.Contract(
    contractAbi,
    projectContractAddress
  );
};

export const getAddressTier = () => {
  return new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;

    try {
      const contractData = await contractInstance.methods
        .getAddressTier(buyAddress)
        .call();

      resolve(contractData);
    } catch (error) {
      reject(error);
    }
  });
};

export const getTierMaxAmountThatCanBeInvested = (tier) => {
  return new Promise(async (resolve) => {
    const contractData = await contractInstance.methods
      .tierMaxAmountThatCanBeInvested(tier)
      .call();

    resolve(convertToEther(contractData, tokenInfo.decimals));
  });
};

export const getTierData = (tier) => {
  return new Promise(async (resolve) => {
    const contractData = await contractInstance.methods
      .tierAllocations(tier)
      .call();

    resolve(convertToEther(contractData, tokenInfo.decimals));
  });
};

export const getTokensAvailableInTier = (tier) => {
  return new Promise(async (resolve) => {
    const contractData = await contractInstance.methods
      .tokensAvailableInTier(tier)
      .call();

    resolve(convertToEther(contractData, tokenInfo.decimals));
  });
};

// export const getTierAllocations = (tier) => {
//   return new Promise(async (resolve) => {
//     const contractData = await contractInstance.methods
//       .tierAllocations(tier)
//       .call();

//     resolve(convertToEther(contractData, tokenInfo.decimals));
//   });
// };

const approveToken = (amount) => {
  return new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;
    // let amountToWei = await web3.utils.toWei(amount, tokenInfo.decimals);
    // console.log(contractInstance._address);
    let currencyContractInstance = await createCurrencyContractInstance();

    try {
      const contractData = await currencyContractInstance.methods
        .approve(contractInstance._address, amount)
        .send({
          from: buyAddress,
          gas: 46194,
        });
      // console.log(contractData);
      resolve(contractData);
    } catch (error) {
      reject(error);
      toast.error("The token could not have been approved");
    }
  });
};
const approveErcToken = (amount, projectContractAddress) => {
  return new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;
    // let amountToWei = await web3.utils.toWei(amount, tokenInfo.decimals);
    // console.log(contractInstance._address);
    let currencyContractInstance = await createCurrencyContractInstance();

    try {
      const contractData = await currencyContractInstance.methods
        .approve(projectContractAddress, amount)
        .send({
          from: buyAddress,
          gas: 500000,
        });
      // console.log(contractData);
      resolve(contractData);
    } catch (error) {
      reject(error);
      toast.error("The token could not have been approved");
    }
  });
};
export const getAvailableTokens = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await contractInstance.methods.availableTokens().call();
      data = convertToEther(data, tokenInfo.decimals);
      resolve(data);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getAddressBelongsToTier = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await contractInstance.methods
        .addressBelongsToTier(address)
        .call();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getRound1StartTime = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let round2Start = await contractInstance.methods.round1Start().call();
      resolve(round2Start);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getRound2StartTime = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let round2Start = await contractInstance.methods.round2Start().call();
      resolve(round2Start);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getNumberParticipants = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await contractInstance.methods.numberParticipants().call();
      resolve(data);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getAllRoundsTime = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let round1Start = await contractInstance.methods.round1Start().call();
      let round1End = await contractInstance.methods.round1End().call();
      let round2Start = await contractInstance.methods.round2Start().call();
      let round2End = await contractInstance.methods.round2End().call();

      let data = {
        round1Start: round1Start,
        round1End: round1End,
        round2Start: round2Start,
        round2End: round2End,
      };
      resolve(data);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const isPoolActiveWithMessages = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let round1Start = await contractInstance.methods.round1Start().call();
      let round1End = await contractInstance.methods.round1End().call();
      let round2Start = await contractInstance.methods.round2Start().call();
      let round2End = await contractInstance.methods.round2End().call();
      let currentTime = moment().unix();

      if (currentTime > round1Start && currentTime < round1End) {
        resolve({
          current: "round1",
          status: true,
        });
      }

      if (currentTime > round1End && currentTime < round2Start) {
        toast.info("Rouond 1 has finished please wait for FCFS round");
        resolve({
          current: "fcfs",
          status: false,
        });
      }

      if (currentTime > round2Start && currentTime < round2End) {
        resolve({
          current: "fcfs",
          status: true,
        });
      }

      if (currentTime > round2End) {
        toast.info("The Buy Time has expired");
        resolve({
          current: "fcfs",
          status: false,
        });
      }
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const isPoolActive = () => {
  return new Promise(async (resolve, reject) => {
    let { data } = await api.getReplace(
      "projects/client/v1/getCurrentDateTime"
    );
    const timestampFromServer = new Date(data.date).toISOString();
    try {
      let round1Start = await contractInstance.methods.round1Start().call();
      let round1End = await contractInstance.methods.round1End().call();
      let round2Start = await contractInstance.methods.round2Start().call();
      let round2End = await contractInstance.methods.round2End().call();
      let currentTime = moment(timestampFromServer).unix();

      if (currentTime > round1Start && currentTime < round1End) {
        resolve({
          current: "round1",
          status: true,
        });
      }

      if (currentTime > round1End && currentTime < round2Start) {
        resolve({
          current: "fcfs",
          status: "upcoming",
        });
      }

      if (currentTime > round2Start && currentTime < round2End) {
        resolve({
          current: "fcfs",
          status: true,
        });
      }

      if (currentTime > round2End) {
        resolve({
          current: "fcfs",
          status: false,
        });
      }
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const checkIfRoundIsFinished = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let round1Start = await contractInstance.methods.round1Start().call();
      let round1End = await contractInstance.methods.round1End().call();
      let round2Start = await contractInstance.methods.round2Start().call();
      let round2End = await contractInstance.methods.round2End().call();
      let currentTime = moment().unix();
      // if (contractData > moment().unix()) {
      //   resolve(true);
      // } else {
      //   reject(false);
      //   toast.info("Please wait for the Release Time");
      // }

      let round1Check = round1Start > currentTime && currentTime < round1End;
      let round2Check = round2Start > currentTime && currentTime < round2End;
      // let round2Check = round2Start < currentTime < round2End;

      // console.log(round1Check, round2Check);

      if (round1Check || round2Check) {
        resolve(true);
      } else {
        reject(false);
        toast.info("The Buy Token time has expired");
      }

      resolve(true);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const buyPoolToken = (amount, projectContractAddress, tokenPrice) => {
  return new Promise(async (resolve, reject) => {
    if (amount === "") {
      toast.info("Please enter a valid amount");
      return;
    }

    amount = parseFloat(amount);

    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;

    let amountOfToken = parseFloat(amount) / tokenPrice;

    amountOfToken = roundOffBuyValue(amountOfToken);

    // amountOfToken = parseFloat

    // amountOfToken = parseFloat(amountOfToken).toFixed(
    //   parseFloat(tokenInfo.decimals)
    // );

    // let tokenPriceToWei = web3.utils.toWei(tokenPrice.toString(), tokenInfo.decimals);

    let amountToWei = await convertToDecimal(
      (amount + 100).toString(),
      tokenInfo.decimals
    );

    let amountOfTokenToWei = await convertToDecimal(
      amountOfToken.toString(),
      tokenInfo.decimals
    );

    await approveErcToken(amountToWei, projectContractAddress);

    let convertToBigNumberAmountToken = "";
    if (tokenInfo.decimals === 18) {
      convertToBigNumberAmountToken = web3.utils
        .toBN(amountOfTokenToWei)
        .toString();
    } else {
      convertToBigNumberAmountToken = web3.utils
        .toBN(amountOfTokenToWei)
        .toString();
    }

    try {
      const contractData = await contractInstance.methods
        .buy(convertToBigNumberAmountToken)
        .send({
          from: buyAddress,
          gas: 500000,
        });

      resolve(contractData);
    } catch (error) {
      console.log(error);
      toast.error("Could not buy the token from the pool");
      reject(error);
    }
  });
};

export const getReleaseTime = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const contractData = await contractInstance.methods.releaseTime().call();
      // console.log(contractData);

      // let ddd = moment
      //   .unix(contractData)
      //   .format("dddd, MMMM Do, YYYY h:mm:ss A");

      // console.log(moment.utc(), moment.utc().valueOf());

      // let ddd1 = moment.utc().valueOf();

      // console.log(ddd, ddd1);
      // // console.log(moment.unix(contractData).format("h:mm:ss"));

      // let contractDate = {
      //   day: moment.unix(contractData).format("dddd"),
      //   month: moment.unix(contractData).format("MMMM"),
      //   fi: moment.unix(contractData).format("D"),
      //   fi2: moment(ddd1).format("D"),
      //   f: moment.unix(contractData).format("Do"),

      //   day1: moment(ddd1).format("dddd"),
      // };

      // console.log("hi", contractDate);

      // console.log(moment.utc());
      // console.log(moment.utc(contractData));
      // let tmsx = moment(contractData).format("X");
      // console.log(tmsx);
      // console.log(momen);

      // console.log(contractData < moment().unix());
      // console.log(contractData > moment().unix());
      // console.log(contractData === moment().unix());

      if (moment().unix() > contractData) {
        resolve(true);
      } else {
        reject(false);
        toast.info("Please wait for the Release Time");
      }

      // resolve(true);

      // resolve(contractData);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const checkSales = () => {
  return new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();

    let buyAddress = account.address;

    // buyAddress = "0x6436069d93373Ab48b5Ff65FB06f95Fd54410925";

    try {
      const contractData = await contractInstance.methods
        .sales(buyAddress)
        .call();
      const { amount, tokensWithdrawn, investor } = contractData;
      // console.log(contractData);

      // if (amount === "0") {
      //   toast.info("There are no purchased tokens");
      // }

      resolve({
        amount: await convertToEther(amount, tokenInfo.decimals),
        tokensWithdrawn,
        investor,
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const claimTokens = () => {
  return new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;
    // console.log(await checkSales());
    let releaseTime = await getReleaseTime();
    if (releaseTime === false) {
      return;
    }

    let saleData = await checkSales();
    if (saleData.tokensWithdrawn === false) {
      try {
        const contractData = await contractInstance.methods.claimTokens().send({
          from: buyAddress,
        });
        // console.log(contractData);
        resolve(contractData);
      } catch (error) {
        reject(error);
        toast.error(
          "Some error occurred while claiming the tokens from the pool"
        );
      }
    } else if (saleData.tokensWithdrawn === true) {
      toast.error("The tokens have already been claimed");
    }
  });
};
