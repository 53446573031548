import { createStore, combineReducers, applyMiddleware } from "redux";
import reducer from "./reducers/reducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const combineReducer = combineReducers({
  reducer,
});

const middelware = [thunk];
const store = createStore(
  combineReducer,
  composeWithDevTools(applyMiddleware(...middelware))
);

export default store;
