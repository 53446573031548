export const calculatePriceOfTokens = (tokens, price) => {
  tokens = parseFloat(tokens);
  price = parseFloat(price);
  let priceOfToken = parseFloat(parseFloat(tokens * price));

  priceOfToken = roundOffBuyValue(priceOfToken);

  if (priceOfToken < 0.1) return 0;

  return Math.ceil(priceOfToken);
};

export const calculatePriceOfTokensRoundUp = (tokens, price) => {
  tokens = parseFloat(tokens);
  price = parseFloat(price);
  let priceOfToken = parseFloat(parseFloat(tokens * price));

  priceOfToken = roundOffBuyValue(priceOfToken);

  if (priceOfToken < 0.1) return 0;

  return Math.ceil(priceOfToken);
};

export const calculatePriceOfTokensRoundDown = (tokens, price) => {
  tokens = parseFloat(tokens);
  price = parseFloat(price);
  let priceOfToken = parseFloat(parseFloat(tokens * price));

  priceOfToken = roundOffBuyValue(priceOfToken);

  if (priceOfToken < 0.1) return 0;

  return Math.floor(priceOfToken);
};

export const roundOffBuyValue = (price) => {
  if (typeof price !== "string") price = price.toString();

  let roundOffPrice = price;

  if (price.indexOf(".") !== -1) {
    const decimalIndex = price.indexOf(".");
    roundOffPrice =
      price.substring(0, decimalIndex + 1) +
      price.substring(decimalIndex + 1, decimalIndex + 3);
    return parseFloat(roundOffPrice);
  }

  return parseFloat(roundOffPrice);
};

export function convertNegetiveNumberToNumber(number) {
  if (number < 0.001) return 0;
  return Math.ceil(number);
}
