import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import Progress from "react-progressbar";
import Countdown from "react-countdown";
import Loader from "../../../../components/Loader/Loader";
import {
  getAllRoundsTime,
  getRound1StartTime,
  isPoolActive,
} from "../../../../Web3/web3";
import api from "../../../../config/apiCall";
import { web3Class } from "../../../../Web3/web3Class";
import "./Sidebar.css";
import Timer from "../../../../components/Timer/Timer";
import NetworkImg from "../../../../components/NetworkImg/NetworkImg";

const PoolProgress = ({ singlePoolData }) => {
  const [loading, setLoading] = useState(false);
  const [addressTier, setAddressTier] = useState(0);
  const [tierPercentage, setTierPercentage] = useState(0);
  const isSocketConnected = useSelector(
    (state) => state.reducer.socket.isConnected
  );
  const socket = useSelector((state) => state.reducer.socket.data);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      try {
        // const contractDetails = {
        //   contractAddress: singlePoolData.projectContractAddress,
        //   networkId: singlePoolData.projectNetwork.networkId,
        //   currencyAbi: singlePoolData.abi.currencyABI,
        //   tokenAbi: singlePoolData.abi.tokenABI,
        //   currencyAddress: singlePoolData.smartContractAddress.currency,
        //   tokenAddress: singlePoolData.smartContractAddress.token,
        // };
        let web3Instance = new web3Class(singlePoolData);

        let percentage = await web3Instance.calculateOverallPoolPercentage();

        /* Colizeum Community IDO to be removed later */

        if (singlePoolData._id === 1646366485377) {
          setTierPercentage(100);
        } else {
          setTierPercentage(percentage);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    getData();
  }, [singlePoolData]);

  useEffect(() => {
    if (isSocketConnected) {
      socket.on("project-progress", (projectData) => {
        if (projectData.projectId === singlePoolData._id)
          setTierPercentage(projectData.overallPercentage);
      });
    }
  }, [isSocketConnected, socket, singlePoolData]);

  if (loading) {
    return <Loader width="50px" />;
  }

  return (
    <>
      <div className="pool-progress">
        <div className="header">
          <p className="heading">Progress</p>
          <p className="value">{`${tierPercentage} %`}</p>
        </div>
        <Progress color={"#FF2D78"} completed={tierPercentage} />
      </div>
    </>
  );
};

const SidebarTimer = ({ singlePoolData }) => {
  const [projectTimer, setProjectTimer] = useState(1636142640000);
  const [projectTimerLoading, setProjectTimerLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateTimer, setUpdateTimer] = useState(false);
  const [currentTime, setCurrentTime] = useState(1636142640000);
  // const [timestampFromServer, setTimestampFromServer] = useState(1636142640000);
  const [projectStatus, setProjectStatus] = useState({
    current: "",
    status: "",
  });

  const [tokensLeft, setTokensLeft] = useState();
  const [dollarValueLeft, setDollarValueLeft] = useState();

  useEffect(() => {
    if (singlePoolData.projectContractAddress) {
      const fetchData = async () => {
        let web3Instance = new web3Class(singlePoolData);

        let tierMax =
          await web3Instance.calculateTotalAvailableTokensInAllTiers();
        setTokensLeft(tierMax);
        // console.log(tierMax, "Tmm");
        let tokenPrice = singlePoolData.tokenDetails.tokenPrice;
        setDollarValueLeft(tierMax * tokenPrice);
      };

      fetchData();
    }
  }, []);

  const isSocketConnected = useSelector(
    (state) => state.reducer.socket.isConnected
  );
  const socket = useSelector((state) => state.reducer.socket.data);

  useEffect(() => {
    if (isSocketConnected) {
      // console.log("connected");
      socket.on("project-progress", (projectData) => {
        // console.log("sdjhsjka", projectData);
        if (projectData.projectId === singlePoolData._id) {
          // console.log(projectData);
          setTokensLeft(projectData.availableTokens);
          let tokenPrice = singlePoolData.tokenDetails.tokenPrice;
          setDollarValueLeft(projectData.availableTokens * tokenPrice);
          // setTimestampFromServer(new Date(projectData.time).toISOString());
          setCurrentTime(projectData.time);
          // console.log("RDV", projectData.availableTokens * tokenPrice, projectData.availableTokens);
        }
        // console.log("percent%%%", projectData.overallPercentage);
      });
    }
  }, [isSocketConnected, singlePoolData, socket]);

  const getTimerData = async () => {
    setUpdateTimer(true);
    setProjectTimerLoading(true);
    let roundTime = "";
    let { data } = await api.getReplace(
      "projects/client/v1/getCurrentDateTime"
    );
    const timestampFromServer = new Date(data.date).toISOString();
    setCurrentTime(data.date);
    if (singlePoolData.projectStatus === "active") {
      // const contractDetails = {
      //   contractAddress: singlePoolData.projectContractAddress,
      //   networkId: singlePoolData.projectNetwork.networkId,
      //   currencyAbi: singlePoolData.abi.currencyABI,
      //   tokenAbi: singlePoolData.abi.tokenABI,
      //   currencyAddress: singlePoolData.smartContractAddress.currency,
      //   tokenAddress: singlePoolData.smartContractAddress.token,
      // };
      let web3Instance = new web3Class(singlePoolData);

      let allPoolTime = await web3Instance.getAllRoundsTime(
        singlePoolData.projectContractAddress
      );
      let data = await isPoolActive();
      setProjectStatus(data);
      if (data.current === "round1") {
        roundTime = parseInt(allPoolTime.round1End + "000");
        setProjectTimer(roundTime);
      }

      if (data.current === "fcfs" && data.status === "upcoming") {
        roundTime = parseInt(allPoolTime.round2Start + "000");
        setProjectTimer(roundTime);
      }

      if (data.current === "fcfs" && data.status === true) {
        roundTime = parseInt(allPoolTime.round2End + "000");
        setProjectTimer(roundTime);
      }
    } else if (singlePoolData.projectStatus === "upcoming") {
      // roundTime = await moment(
      //   singlePoolData.idoDates.whitelistEndDate.toString()
      // ).valueOf();

      if (timestampFromServer < singlePoolData.idoDates.whitelistStartDate) {
        roundTime = await moment(
          singlePoolData.idoDates.whitelistStartDate.toString()
        ).valueOf();
      }

      if (
        timestampFromServer > singlePoolData.idoDates.whitelistStartDate &&
        timestampFromServer < singlePoolData.idoDates.whitelistEndDate
      ) {
        roundTime = await moment(
          singlePoolData.idoDates.whitelistEndDate.toString()
        ).valueOf();
      }

      if (
        timestampFromServer > singlePoolData.idoDates.whitelistEndDate &&
        timestampFromServer < singlePoolData.idoDates.roundOneStartDate
      ) {
        roundTime = await moment(
          singlePoolData.idoDates.roundOneStartDate.toString()
        ).valueOf();
      }

      // roundTime = parseInt(singlePoolData.idoDates.whitelistEndDate);
      setProjectTimer(roundTime);
    }
    setProjectTimerLoading(false);
  };

  useEffect(
    async () => {
      setLoading(true);

      if (
        singlePoolData.projectStatus === "active" ||
        singlePoolData.projectStatus === "upcoming"
      ) {
        getTimerData();
      }
      setLoading(false);
    },
    []
    // singlePoolData.projectStatus === "upcoming" ? [currentTime] : []
  );
  // console.log("current time", currentTime);
  // console.log("round time", projectTimer);
  // console.log(
  //   "diffference time",
  //   new Date(projectTimer).getTime() - currentTime
  // );
  return (
    <div className="whitelist-timer">
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* {updateTimer && <Timer />} */}
          <p className="whitelist-timer-heading">
            {singlePoolData.projectStatus === "active" &&
              projectStatus.current === "round1" &&
              projectStatus.status === true &&
              (Math.floor(tokensLeft) == 0 || Math.floor(dollarValueLeft) == 0
                ? "Thank you for participation!"
                : "Token Sale Ends In")}

            {singlePoolData.projectStatus === "active" &&
              projectStatus.current === "fcfs" &&
              projectStatus.status === "upcoming" &&
              (Math.floor(tokensLeft) == 0 || Math.floor(dollarValueLeft) == 0
                ? "Thank you for participation!"
                : "FCFS round Starts in")}

            {singlePoolData.projectStatus === "active" &&
              projectStatus.current === "fcfs" &&
              projectStatus.status === true &&
              (Math.floor(tokensLeft) == 0 || Math.floor(dollarValueLeft) == 0
                ? "Thank you for participation!"
                : "FCFS round ends in")}

            {/* {singlePoolData.projectStatus === "upcoming" &&
              "Whitelist Starts In"} */}

            {singlePoolData.projectStatus === "upcoming"
              ? new Date(currentTime).toISOString() <
                singlePoolData.idoDates.whitelistEndDate
                ? `Whitelist 

              ${
                new Date(currentTime).toISOString() <
                singlePoolData.idoDates.whitelistStartDate
                  ? "starts"
                  : "ends"
              }

              
              
              In`
                : `Token Sale Starts In`
              : null}

            {singlePoolData.projectStatus === "ended" && "Token Sale Closed"}
          </p>
          <p className="whitelist-timer-coming-soon">
            {/* {singlePoolData.projectStatus === "upcoming" && "Coming Soon"} */}

            {singlePoolData.projectStatus === "active" &&
              (projectTimerLoading ? (
                <Loader />
              ) : Math.floor(tokensLeft) == 0 ||
                Math.floor(dollarValueLeft) == 0 ? (
                "SOLD OUT"
              ) : (
                <Countdown
                  // date={element.projectDetails.launchDate}
                  // daysInHours={true}
                  // key={element._id}
                  className="timer"
                  date={
                    Date.now(currentTime) +
                    (new Date(projectTimer).getTime() - currentTime)
                  }
                  onComplete={() => window.location.reload()}
                />
              ))}

            {/* {singlePoolData.projectStatus === "upcoming" &&
              (projectTimerLoading ? (
                <Loader />
              ) : (
                <Countdown className="timer" date={projectTimer} />
              ))} */}

            {/* {singlePoolData.projectStatus === "upcoming" &&
              "Whitelisting Started"} */}

            {singlePoolData.projectStatus === "ended" &&
              "Thank you for participation!"}

            {singlePoolData.projectStatus === "upcoming" &&
              (projectTimerLoading ? (
                <Loader />
              ) : singlePoolData.isTBA === true ? (
                "Coming Soon"
              ) : (
                <Countdown
                  // date={element.projectDetails.launchDate}
                  // daysInHours={true}
                  // key={element._id}
                  className="timer"
                  date={
                    Date.now(currentTime) +
                    (new Date(projectTimer).getTime() - currentTime)
                  }
                  onComplete={() => window.location.reload()}
                />
              ))}
          </p>
        </>
      )}
    </div>
  );
};

const Sidebar = ({ singlePoolData }) => {
  const [tokenSaleDate, setTokenSaleDate] = useState("");

  const getTokenSaleDate = async () => {
    // console.log(singlePoolData);
    // console.log(singlePoolData.tokenDetails.tokenSaleDate);
    let saleDate = singlePoolData.tokenDetails.tokenSaleDate;

    // let saleDateTimestamp = await moment(saleDate.toString()).valueOf();

    // let saleDateUTC = await moment.utc(saleDate).valueOf();

    let myMoment = await moment.utc(saleDate).format("MMMM Do YYYY HH:mm");

    // console.log(
    //   saleDate,
    //   saleDateTimestamp,
    //   saleDateUTC,
    //   myMoment
    // );
    // console.log(myMoment);
    setTokenSaleDate(myMoment);
  };

  useEffect(() => {
    getTokenSaleDate();
  }, []);

  return (
    <div className="sidebar">
      <div className="image">
        <img
          src={singlePoolData.projectBannerUrl}
          className="sidebar__projectImage"
          alt="projectBannerUrl"
        />
        <img
          src={singlePoolData.projectLogoUrl}
          alt="user"
          className="avatar"
        />
      </div>

      <div className="ido-details">
        <p className="name">{singlePoolData.projectName}</p>
        <div className="tags">
          <span className="tag projectType">
            <div className={`dot ${singlePoolData.projectStatus}`}></div>
            <span className="uppercase">{singlePoolData.projectStatus}</span>
          </span>
          <span className="tag type">{singlePoolData.typeOfProject}</span>
          <span className="tag network">
            {singlePoolData.projectNetwork.networkName}
            <NetworkImg
              networkId={singlePoolData.projectNetwork.networkId}
              networkName={singlePoolData.projectNetwork.networkName}
              className="network-icon"
            />
          </span>
        </div>
        <div className={`currentTokenStatus ${singlePoolData.projectStatus}`}>
          {singlePoolData.projectStatus === "active" &&
            "Token Sale in Progress"}

          {singlePoolData.projectStatus === "upcoming" &&
            (singlePoolData.isTBA === true ? "TBA" : `${tokenSaleDate} UTC`)}
          {singlePoolData.projectStatus === "ended" && "The sale is finished"}
        </div>

        <div className="hashtags">
          {singlePoolData.projectTags.length > 0 &&
            singlePoolData.projectTags.split(",").map((tag, index) => (
              <p key={index} className="hash">
                # {tag}
              </p>
            ))}
        </div>

        <SidebarTimer singlePoolData={singlePoolData} />

        <div className="ido-list-details">
          <div className="ido-list-details-col">
            <p className="heading">Total Raise</p>
            {singlePoolData.tokenDetails.totalFundraise == 0 ? (
              <p className="value">TBA</p>
            ) : (
              <p className="value">
                ${singlePoolData.tokenDetails.totalFundraise}
              </p>
            )}
          </div>
          <div className="ido-list-details-col">
            <p className="heading">Rate</p>
            <p className="value">{singlePoolData.tokenDetails.rate}</p>
          </div>
          <div className="ido-list-details-col">
            <p className="heading">Supported Coin</p>
            <p className="value">{singlePoolData.supportedToken}</p>
          </div>
        </div>
      </div>
      {singlePoolData.projectStatus !== "upcoming" && (
        <PoolProgress singlePoolData={singlePoolData} />
      )}
    </div>
  );
};

export default Sidebar;
