import './footer.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/metalaunch.png';

const Footer = () => {

    const scrollTo = () => {
        window.scrollTo(0, document.getElementById('upcomingIDO').offsetTop - 90)
    }

    return (
        <div className="footer">
            <div className="container-box">
                <div className="footer-nav">
                    <div className="footer-nav-box pr-5">
                        <Link to="/home"><img src={logo}></img></Link>
                        <p className="smallText">Premier launchpad for GameFi and Metaverse</p>
                    </div>
                    <div className="footer-nav-box ">
                        <Link to="/projects"><p>Projects</p></Link>
                        <p>Stake Asva </p>
                        <p>Docs</p>
                        <a href="mailto:contact@asvaventures.com"><p>Contact</p></a>
                    </div>
                    <div className="footer-nav-box ">
                        <a href="https://docs.google.com/forms/d/12Ue_5OJOu2f-xtytcMfjyl1tpeP01qZWqCJaEMjcO8I/edit" target="_blank"><p>Apply for IDO</p></a>
                        <a href="https://docs.google.com/forms/d/1CY0eZEhRjapUzMPRGfRVOzbhayA-3NfAhqXmoNBIcvU/edit" target="_blank"><p>Apply for Incubation</p></a>
                        <a href="https://forms.gle/RqQRypKBj9R86m4K7" target="_blank"><p>Asva Warriors</p></a>
                    </div>
                    <div className="footer-nav-box ">
                        <a href="https://twitter.com/AsvaVentures" target="_blank"><p>Twitter</p></a>
                        <a href="https://t.me/asvafinance" target="_blank"><p>Telegram</p></a>
                        <a href="https://asvafinance.medium.com" target="_blank"><p>Medium</p></a>
                        <a href="https://discord.com/invite/HPTykQyRPY" target="_blank"><p>Discord</p></a>
                    </div>
                    <div className="footer-nav-box ">
                        <a target="_blank" href="/Terms-of-Use_Asva.Finance.pdf"><p>Terms of Use</p></a>
                        <a target="_blank" href="/Privacy-Policy_Asva.Finance.pdf"><p>Privacy Policy</p></a>
                    </div>
                </div>
                <div className="copyright">
                    <p className="m-0">Copyright <i className="fas fa-copyright"></i> 2021-2022 ASVA LABS</p>
                </div>
            </div>
        </div>
    )
}

export default Footer